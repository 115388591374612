import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import qs from 'querystring';

export default function useSearchParams<T>(defaultParams?: T): T {
  const location = useLocation();
  const [params, setParams] = useState<T>(defaultParams || ({} as T));

  useEffect(() => {
    if (location.search) {
      setParams(qs.parse(location.search.substring(1)) as unknown as T);
    }
  }, [location]);

  return params;
}
