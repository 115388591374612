import { AxiosError } from 'axios';
export default class APIError extends Error {
  code: number;

  constructor(error: AxiosError) {
    super();
    this.message = error.response.data.message;
    this.code = error.response.status;
  }

  toString() {
    return 'Error ' + this.code + ' : ' + this.message;
  }
}
