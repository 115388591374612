import { Instance } from './API/API';
import Base from './Base';
import Program, { ProgramLevel, ProgramType } from './Program';
import ProgramDay from './ProgramDay';
import ProgramWeek from './ProgramWeek';
import User from './User';
import Evaluation from './Evaluation';

class Advancement extends Base {
  isActive: boolean;
  isEnded: boolean;
  level: ProgramLevel;
  type: ProgramType;
  program?: Program;
  programId: string;
  week: number;
  day: number;
  canGoToNextStep: boolean;
  evaluations: Evaluation[];

  getCurrentDay(program: Program) {
    const day = program.weeks
      .filter((w) => w.number === this.week)[0]
      .days.filter((d) => d.number === this.day);
    return ProgramDay.fromJson(day);
  }

  getWeek(number?: number) {
    return this.program.weeks.find((w) => w.number === (number || this.week));
  }

  async submitEvaluation(user: User, evaluation?: Evaluation) {
    const { data } = await Instance(true).request.patch<{ success: boolean }>(
      `/v1/users/${user.hash}/advancements/${this.hash}`,
      { ...evaluation },
    );
    return data;
  }

  public static async get(
    user: User,
    active: boolean,
    includeProgram: boolean,
  ): Promise<Advancement[]> {
    const { data } = await Instance(true).request.get<{
      success: boolean;
      progressions: Advancement[];
    }>(`/v1/progressions`, {
      params: { userId: user.hash, active, includeProgram },
    });
    return data.progressions;
  }

  async update(user: User) {
    const { data } = await Instance(true).request.patch<{ success: boolean }>(
      `/v1/users/${user.hash}/advancements/${this.hash}/progression`,
      {
        level: this.level,
        week: this.week,
        day: this.day,
        isActive: this.isActive,
        isEnded: this.isEnded,
      },
    );
    return data;
  }

  async nextDay() {
    const { data } = await Instance(true).request.post<{ success: boolean }>(
      `/v1/progressions/${this.hash}/advance`,
    );
    return data;
  }

  async delete() {
    const { data } = await Instance(true).request.delete<{ success: boolean }>(
      `/v1/progressions/${this.hash}`,
    );
    return data;
  }

  static fromJson(object: Record<string, any>) {
    const adv = Object.assign(
      new Advancement(),
      { ...object },
      { program: object.program ? Program.fromJson(object.program) : null },
    );

    if (object.evaluations.length) {
      adv.evaluations = object.evaluations.map((ev) => Evaluation.fromJSON(ev));
    }

    return adv;
  }
}

export default Advancement;
