import React, { useState } from 'react';
import './../assets/globals/style.css';
import { useLocation } from 'react-router';
import qs from 'qs';
import Layout from '../components/Layout';
import User from '../classes/User';
import Input from '../components/forms/Input';
import Alert, { AlertType } from '../components/Alert';
import { Enveloppe, Key } from '../assets/img/icons';
import Button from '../components/forms/Button';
import { useHistory } from 'react-router-dom';

enum Field {
  email,
  password,
  confirmPassword,
}

const ResetPassword = () => {
  const [user, setUser] = useState(new User());
  const [isLoading, setLoading] = useState(false);
  const [popup, setPopup] = useState<JSX.Element | null>(null);

  const navigate = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search.substring(1));

  const requestPassword = async () => {
    setLoading(true);
    await user.requestNewPassword();
    setPopup(
      <Alert
        type={AlertType.success}
        title={'Nous vous avons envoyé un email'}
        body={
          'Si votre email est associé a un compte, nous vous avons envoyé un email pour renouveler votre mot de passe'
        }
      />,
    );
    setLoading(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    await user.resetPassword(search.token);
    setPopup(
      <Alert
        type={AlertType.success}
        title={"C'est fait !"}
        body={
          "Votre mot de passe a été changé avec succès. Vous allez être redirigé vers l'application"
        }
      />,
    );
    setLoading(false);
    setTimeout(() => navigate.push('/mon-compte'), 2000);
  };

  const updateFields = (value: string, field: Field) => {
    const _user = user;

    switch (field) {
      case Field.email:
        _user.email = value;
        break;
      case Field.password:
        _user.password = value;
        break;
      case Field.confirmPassword:
        _user.confirmPassword = value;
        break;
    }
    setUser(_user);
  };

  const emailInput = () => {
    return (
      <>
        <h2 className={'text-2xl font-medium mb-6 text-gray-600'}>
          Changez votre mot de passe
        </h2>
        <p className={'text-gray-500 mb-4'}>
          Nous vous enverrons un email contenant un lien pour changer votre mot
          de passe
        </p>
        {popup}
        <Input
          leftComponent={
            <Enveloppe className={'stroke-current text-gray-400'} />
          }
          placeholder={'Votre email'}
          type={'email'}
          onChange={(v) => updateFields(v, Field.email)}
        />

        <Button onClick={requestPassword}>
          <>Récupérer mon mot de passe</>
        </Button>
      </>
    );
  };

  const resetInput = () => {
    return (
      <>
        <h2 className={'font-bold text-xl mb-4 text-teal'}>
          Changer mon mot de passe
        </h2>
        {popup}
        <Input
          leftComponent={<Key className={'stroke-current text-gray-400'} />}
          placeholder={'Votre nouveau mot de passe'}
          type={'password'}
          onChange={(v) => updateFields(v, Field.password)}
        />
        <Input
          leftComponent={<Key className={'stroke-current text-gray-400'} />}
          placeholder={'Confirmer votre nouveau mot de passe'}
          type={'password'}
          onChange={(v) => updateFields(v, Field.confirmPassword)}
        />

        <Button onClick={resetPassword}>
          <>Changer mon mot de passe</>
        </Button>
      </>
    );
  };

  return (
    <Layout showJulien={false} showLogo={true}>
      {search.reset === 'true' && !!search.token ? resetInput() : emailInput()}
    </Layout>
  );
};

export default ResetPassword;
