import { Instance } from './API/API';
import Training from './Training';
import Video from './Video';
import Base from './Base';

export default class TrainingSession extends Base {
  number?: number;
  name!: string;
  slug!: string;
  description?: string;
  visibleToCustomer = true;
  trainingId?: string;
  videoId?: string;
  training?: Training;
  video?: Video;

  constructor() {
    super();
    this.training = new Training();
    this.video = new Video();
  }

  static sort(t1: TrainingSession, t2: TrainingSession): number {
    if (t1.number == t2.number) {
      return 0;
    } else if (t1.number > t2.number) {
      return 1;
    } else {
      return -1;
    }
  }

  static async getAll(): Promise<TrainingSession[]> {
    try {
      const response = await Instance(true).request.get<{
        success: string;
        sessions: TrainingSession[];
      }>(`/v1/sessions`);

      return response.data.sessions.map((session: TrainingSession) =>
        Object.assign(
          new TrainingSession(),
          session,
          {
            training: Object.assign(new Training(), session.training),
          },
          {
            video: Object.assign(new Video(), session.video),
          },
        ),
      );
    } catch (error) {
      throw error;
    }
  }

  async create(): Promise<TrainingSession> {
    try {
      const response = await Instance(true).request.post<{
        success: string;
        session: TrainingSession;
      }>(`/v1/sessions`, {
        ...this,
      });

      this.id = response.data.session.id;
      this.hash = response.data.session.hash;
      this.training = Object.assign(
        new Training(),
        response.data.session.training,
      );
      this.video = Object.assign(new Video(), response.data.session.video);
      return this;
    } catch (error) {
      throw error;
    }
  }

  async update(): Promise<TrainingSession> {
    try {
      const response = await Instance(true).request.put<{
        success: string;
        session: TrainingSession;
      }>(`/v1/sessions/${this.hash}`, {
        ...this,
      });
      return response.data.session;
    } catch (error) {
      throw error;
    }
  }

  async delete(): Promise<number> {
    try {
      const response = await Instance(true).request.delete<{
        success: string;
        session: TrainingSession;
      }>(`/v1/sessions/${this.hash}`);
      return response.status;
    } catch (error) {
      throw error;
    }
  }

  static fromJson(object: Record<string, any>): TrainingSession {
    const session = Object.assign(new TrainingSession(), object);
    session.video = Video.fromJson(object.video);
    return session;
  }
}

enum SessionTypes {
  hash,
  name,
  description,
  icon,
  position,
  visibleToCustomers,
}

export { SessionTypes };
