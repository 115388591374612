import {
  IParagraph,
  ISignupForm,
  IVideoContent,
} from '../../interfaces/contentfulModels';
import React, { FC } from 'react';
import MuxContentfulVideoPlayer from '../MuxContentfulVideoPlayer';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';

const paragraphOption: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong className={'font-bold'}>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <>
          <a href={node.data.uri} target={'_blank'} rel={'noreferrer'}>
            {children}
          </a>
        </>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.url.indexOf('images') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <img
              className={'w-2/3 items-center'}
              src={node.data.target.fields.file.url}
            />
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('videos') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <video
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </video>
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('mp3') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <audio
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </audio>
          </div>
        );
      } else {
        return (
          <>
            <p>BLOCKS.EMBEDDED_ASSET</p>
            {JSON.stringify(node)}
            {JSON.stringify(children)}
          </>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className={'mb-4'}>{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        className={
          'font-oswald font-black text-5xl text-teal-600 text-center my-6'
        }
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        className={
          'font-oswald text-4xl text-[#0a3d63] text-center mb-8 font-medium'
        }
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        className={
          'font-oswald text-2xl font-medium text-center text-[#0a3d63]'
        }
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h3
        className={
          'my-3 font-sans text-xl font-base text-center text-[#0a3d63]'
        }
      >
        {children}
      </h3>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className={'list-decimal'}>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={'list-disc'}>{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className={'list-disc my-1'}>{children}</li>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <div className={'bg-blue-900 text-base text-white'}>{children}</div>
    ),
  },
};

const OptinParagraph: FC<{
  paragraph: IParagraph;
  shouldReverse?: boolean;
  className?: string;
}> = ({ paragraph, shouldReverse = true, className = 'flex-col' }) => {
  return (
    <div
      className={clsx(
        'flex flex-col md:flex-row m-16 gap-8 items-center ',
        shouldReverse && 'md:even:flex-row-reverse',
      )}
    >
      {paragraph.fields.video && (
        <MuxContentfulVideoPlayer video={paragraph.fields.video} />
      )}
      {paragraph.fields.image &&
        paragraph.fields.image.fields.file &&
        !paragraph.fields.video && (
          <img
            className={clsx(
              'w-full md:w-1/2 lg:w-1/3 h-fit rounded drop-shadow-lg',
            )}
            src={paragraph.fields.image.fields.file.url}
          />
        )}
      {!!paragraph.fields.content && (
        <div className={'leading-loose text-[#0a3d63] text-md font-regular'}>
          {documentToReactComponents(paragraph.fields.content, paragraphOption)}
        </div>
      )}
    </div>
  );
};
export default OptinParagraph;
