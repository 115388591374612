import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Content from './content.model';
import Section from '../sections/section.model';
import Chapter from '../chapters/chapter.model';
import Page from '../pages/page.model';

type ContentState = {
  contentsLoading: boolean;
  contents: Content[];

  currentContentLoading: boolean;
  currentContent: Content;

  currentSectionLoading: boolean;
  currentSection?: Section;

  currentChapterLoading: boolean;
  currentChapter: Chapter | undefined;

  errors?: string[];
  message?: string;
};

const initialState: ContentState = {
  contentsLoading: false,
  contents: [],

  currentContentLoading: false,
  currentContent: undefined,

  currentSectionLoading: false,
  currentSection: undefined,

  currentChapterLoading: false,
  currentChapter: undefined,

  errors: [],
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    startLoading(
      state,
      action: PayloadAction<'contents' | 'content' | 'section' | 'chapter'>,
    ) {
      switch (action.payload) {
        case 'contents':
          state.contentsLoading = !state.contentsLoading;
          break;
        case 'content':
          state.currentContentLoading = !state.currentContentLoading;
          break;
        case 'section':
          state.currentSectionLoading = !state.currentSectionLoading;
          break;
        case 'chapter':
          state.currentChapterLoading = !state.currentChapterLoading;
          break;
      }
    },
    getContentsSuccess(state, action: PayloadAction<Content[]>) {
      state.contents = action.payload;
    },
    getContentSuccess(state, action: PayloadAction<Content>) {
      state.currentContent = action.payload;
    },
    getSectionSuccess(state, action: PayloadAction<Section>) {
      state.currentSection = action.payload;
    },
    getChapterSuccess(state, action: PayloadAction<Chapter>) {
      state.currentChapter = action.payload;
    },
    clearCurrentContent(state) {
      state.currentSection = undefined;
    },
    clearCurrentSection(state) {
      state.currentSection = undefined;
    },
    clearCurrentChapter(state) {
      state.currentChapter = undefined;
    },
    getError(state, action: PayloadAction<string>) {
      state.errors = [...state.errors, action.payload];
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
});

export const {
  startLoading,
  getContentsSuccess,
  getContentSuccess,
  getSectionSuccess,
  getChapterSuccess,
  getError,
  clearErrors,
  clearCurrentSection,
  clearCurrentContent,
  clearCurrentChapter,
} = contentSlice.actions;

export default contentSlice.reducer;
