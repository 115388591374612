import { useEffect, useState } from 'react';
import { useAppSelector } from './redux';
import { useDispatch } from 'react-redux';
import { listSubscriptions } from '../features/subscriptions/subscriptions.action';
import User from '../classes/User';

const usePurchases = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((store) => store.user.currentUser);
  const subscriptions = useAppSelector(
    (store) => store.subscriptions.subscriptions,
  );
  const [purchasedItems, setPurchasedItems] = useState<
    {
      slug: string;
      active: boolean;
      status: string;
    }[]
  >([]);

  const [hasPurchasedClub, setClubPurchased] = useState(false);

  const getSubscriptions = (user: User) => {
    dispatch(listSubscriptions(user));
  };

  const hasSubscriptionFor = (productSlug: string, active = true) => {
    if (purchasedItems) {
      if (active) {
        return purchasedItems
          .filter((p) => p.slug === productSlug)
          .some((p) => p.active === true);
      } else {
        return purchasedItems.filter((p) => p.slug === productSlug).length > 0;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (user && !subscriptions.length) getSubscriptions(user);
  }, [user]);

  useEffect(() => {
    if (subscriptions.length && !purchasedItems.length) {
      const prds = subscriptions.map((subs) => {
        return {
          slug: subs.product.sellable.slug,
          active: subs.active,
          status: subs.status,
        };
      });
      setPurchasedItems(prds);
    }
  }, [subscriptions]);

  useEffect(() => {
    setClubPurchased(hasSubscriptionFor('club'));
  }, [purchasedItems]);

  return { subscriptions, hasSubscriptionFor, hasPurchasedClub };
};

export default usePurchases;
