import { useEffect, useState } from 'react';
import Subscription from '../features/subscriptions/subscription.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './redux';
import { ProductType } from '../classes/Product';
import { listSubscriptions } from '../features/subscriptions/subscriptions.action';
import useUser from './useUser';
import useAuth from './useAuth';
import usePurchases from './usePurchases';

const useSubscriptions = () => {
  const { user } = useUser();
  const { isTeam } = useAuth();
  const dispatch = useDispatch();
  const { hasSubscriptionFor } = usePurchases();

  const subscriptions = useAppSelector(
    (store) => store.subscriptions.subscriptions,
  );

  const hasFirstLoaded = useAppSelector(
    (store) => store.subscriptions.hasFirstLoaded,
  );

  const subscriptionsAreLoading = useAppSelector(
    (store) => store.subscriptions.isLoading,
  );

  const [hasPurchasedClub, setHasPurchasedClub] = useState(isTeam);
  const [isChallengeUser, setIsChallengeUser] = useState(!isTeam);

  const [hasActiveClubSubscription, setHasActiveClubSubscription] =
    useState(isTeam);

  useEffect(() => {
    if (!subscriptions.length) return;

    const clubSubscriptions = subscriptions.filter(
      (s: Subscription) => s.product.type === ProductType.Club,
    );

    setHasPurchasedClub(isTeam || !!clubSubscriptions.length);

    const hasActiveClubSubscriptions = hasSubscriptionFor('club', true);
    setHasActiveClubSubscription(isTeam || hasActiveClubSubscriptions);
    setIsChallengeUser(!(isTeam || hasActiveClubSubscriptions));
  }, [subscriptions]);

  const loadSubscriptions = (force = false) => {
    if (!user) return;
    dispatch(listSubscriptions(user));
  };

  return {
    subscriptions,
    subscriptionsAreLoading,
    hasPurchasedClub,
    hasActiveClubSubscription,
    hasFirstLoaded,
    isChallengeUser,
    loadSubscriptions,
  };
};

export default useSubscriptions;
