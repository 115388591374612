import { Instance } from './API/API';
import TrainingSession from './TrainingSession';
import File from './File';
import { data } from 'autoprefixer';

export default class Training {
  id!: number;
  hash!: string;
  name!: string;
  slug!: string;
  position?: number;
  iconUrl?: string;
  color?: string;
  cover?: File | string;
  description?: string;
  visibleToCustomer = true;
  sessions?: any[] = [];

  static sort(t1: Training, t2: Training): number {
    if (t1.position == t2.position) {
      return 0;
    } else if (t1.position > t2.position) {
      return 1;
    } else {
      return -1;
    }
  }

  static fromJson(object: Record<string, any>) {
    const training = Object.assign(new Training(), object);
    training.sessions = object.sessions.map((s) => TrainingSession.fromJson(s));

    return training;
  }

  static async getAll(): Promise<Training[]> {
    try {
      const response = await Instance(true).request.get<{
        success: string;
        trainings: Training[];
      }>(`/v1/trainings`);

      return response.data.trainings;
    } catch (error) {
      throw error;
    }
  }

  static async getOne(hashOrSlug: string): Promise<Training> {
    try {
      const response = await Instance(true).request.get<{
        success: string;
        training: Training;
      }>(`/v1/trainings/${hashOrSlug}`);

      return response.data.training;
    } catch (error) {
      throw error;
    }
  }

  async create(): Promise<Training> {
    try {
      const response = await Instance(true).request.post<{
        success: string;
        training: Training;
      }>(`/v1/trainings`, {
        ...this,
      });

      return response.data.training;
    } catch (error) {
      console.error(error.response);
    }
  }

  async update(): Promise<Training> {
    try {
      const response = await Instance(true).request.put<{
        success: string;
        training: Training;
      }>(`/v1/trainings/${this.hash}`, {
        ...this,
      });
      return response.data.training;
    } catch (error) {
      console.error(error.response);
    }
  }

  async delete(): Promise<number> {
    try {
      const response = await Instance(true).request.delete<{
        success: string;
        training: Training;
      }>(`/v1/trainings/${this.hash}`);
      return response.status;
    } catch (error) {
      throw error;
    }
  }
}

enum TrainingTypes {
  hash,
  name,
  description,
  icon,
  position,
  visibleToCustomers,
}

export { TrainingTypes };
