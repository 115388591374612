import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from '../classes/User';
import Advancement from '../classes/Advancement';

type UserState = {
  isLoading: boolean;
  currentUser?: User;
  observedUser?: User;
  observedUserProgressions?: Advancement[];
  all: User[];
  error?: string;
  message?: string;
};

const initialState: UserState = {
  isLoading: false,
  observedUserProgressions: [],
  all: [],
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getSuccess(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
    getAllSuccess(state, action: PayloadAction<User[]>) {
      state.all = action.payload;
    },
    getError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    startLoading(state) {
      state.isLoading = !state.isLoading;
    },
    signInSuccess(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
    signInFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    signUpSuccess(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
      state.error = undefined;
    },
    findOneSuccess(state, action: PayloadAction<User>) {
      state.observedUser = action.payload;
    },
    findProgressionsSuccess(state, action: PayloadAction<Advancement[]>) {
      state.observedUserProgressions = action.payload;
    },

    /* Request new password */
    /* Reset password */
    /* Update User */
    /* Clear the error state */
    userActionFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearUser(state) {
      state.currentUser = null;
      state.observedUser = null;
      state.all = [];
    },
    clearErrors(state) {
      state.error = null;
    },
  },
});

export const {
  startLoading,
  getSuccess,
  signInSuccess,
  signUpSuccess,
  findOneSuccess,
  findProgressionsSuccess,
  userActionFailure,
  clearErrors,
  clearUser,
  getAllSuccess,
} = userSlice.actions;

export default userSlice.reducer;
