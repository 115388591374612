import React, { useEffect, useState } from 'react';
import './../assets/globals/style.css';
import '../assets/scss/SignupForm.sass';
import { useDispatch } from 'react-redux';

import { getUserProfile } from '../actions/user.action';
import { useAppSelector } from '../hooks/redux';
import { addDays, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import Advancement from '../classes/Advancement';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import TrainingSession from '../classes/TrainingSession';
import { ProgramLevel } from '../classes/Program';
import { CloudArrowDownIcon, PrinterIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import useSearchParams from '../hooks/useSearchParams';
import { Loading } from '../assets/img/icons';
import useUser from '../hooks/useUser';
import ProgramWeek from '../classes/ProgramWeek';

const CreateProgram = () => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector((store) => store.user.observedUser);
  const { user_id, show_options } = useSearchParams<{
    user_id: string;
    show_options: string;
  }>();
  const [week, setWeek] = useState<ProgramWeek | null>(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    $crisp.push(['do', 'chat:hide']);
  }, []);

  useEffect(() => {
    if (user_id) {
      dispatch(getUserProfile(user_id as string, true));
    }
  }, [user_id]);

  useEffect(() => {
    if (!!currentUser?.advancements.length) {
      (async () => {
        const progression = currentUser.advancements[0];
        const week = await ProgramWeek.get(
          progression.programId,
          progression.week,
        );
        setWeek(week);
      })();
    }
  }, [currentUser]);

  const createPdf = async (advancement) => {
    setLoading(true);
    const { data } = await axios.get(
      process.env.API_URL + '/v1/programs/get-pdf?user_id=' + user_id,
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `programme-semaine-${advancement.week}-${currentUser.firstname}-${currentUser.lastname}.pdf`.toLowerCase(),
    );
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const Program = ({ week }: { week: ProgramWeek }) => {
    return (
      <div className={'w-4/5 mx-1/10'}>
        <div className={'mt-4 bg-gray-200 rounded w-full p-4'}>
          <strong>A propos de cette semaine de programmation</strong>
          <ReactMarkdown
            remarkPlugins={[gfm]}
            components={{
              p: ({ node, ...props }) => (
                <p style={{ marginTop: 12, marginBottom: 12 }} {...props} />
              ),
            }}
          >
            {week.description}
          </ReactMarkdown>
        </div>
        <div className={''}>
          {week.days.map((day) => {
            return (
              <div className={'flex flex-col w-full'} key={day.hash}>
                <div className={'font-oswald font-medium text-2xl'}>
                  Jour {day.number}
                </div>
                <div className={'flex w-full justify-around'}>
                  <div className={'flex flex-col w-1/3 m-8'}>
                    <img
                      src={(
                        day.compulsorySession as TrainingSession
                      ).video.getThumbnail('png', 300)}
                      className={'ring ring-teal-500 rounded'}
                    />
                    <caption className={'font-oswald font-medium text-xl mt-2'}>
                      Séance principale
                    </caption>
                  </div>
                  <div className={'flex flex-col w-1/3 m-8'}>
                    <img
                      src={(
                        day.optionnalSession as TrainingSession
                      ).video.getThumbnail('png', 300)}
                      className={'ring ring-gray-300 rounded'}
                    />
                    <caption
                      className={
                        'font-oswald font-medium text-xl text-gray-500 mt-2'
                      }
                    >
                      Séance optionnelle
                    </caption>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Header = ({ week }: { week: ProgramWeek }) => {
    return (
      <div
        className={
          'w-full bg-teal-500 flex justify-around p-8 print:bg-teal-500'
        }
      >
        <div
          className={
            'text-center uppercase font-oswald font-medium text-white text-3xl'
          }
        >
          Cette semaine
          <br />
          {week.title}
        </div>
        <div
          className={
            'text-blue-900 text-center uppercase font-oswald font-medium text-3xl'
          }
        >
          Niveau <br />
          <span className={'font-bold'}>
            {currentUser.advancements[0].level === ProgramLevel.Easy
              ? 'Débutant'
              : 'Confirmé'}
          </span>
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <div
        className={
          'relative w-4/5 mx-1/6 font-oswlad text-blue-900 text-xl font-medium'
        }
      >
        <p className={'my-2'}>
          Il n'y a que 5 entraînements dans la semaine tout simplement car dans
          ma méthode, j'accorde énormément d'importance à la régénération
          musculaire et à la thérapie. Combiner ces séances à de la marche
          plusieurs fois par semaine est l'idéal.
        </p>

        <p className={'my-4'}>A tres vite pour votre prochain entrainement</p>
        <div className={'absolute left-0 -bottom-32'}>
          <p className={'my-4  w-full'}>Ciao, ciao ! </p>
          <p className={'my-4 w-full'}>Julien. </p>
        </div>
        <img
          src={require('../assets/img/julien-pouce-on-white.png').default}
          className={'w-1/6 absolute right-0'}
        />
      </div>
    );
  };

  return (
    <div
      className={
        'h-screen w-full flex flex-col items-center justify-start py-12'
      }
    >
      {show_options === 'true' ? (
        <div
          id={'ignorePDF'}
          className={
            ' absolute top-0 right-0 flex w-1/3 p-4 mb-8 justify-end gap-4 print:hidden'
          }
        >
          <button
            className={
              'ring-gray-400 ring-2 rounded-full p-4 text-gray-600 flex items-center justify-center hover:bg-gray-600 hover:ring-gray-600 hover:text-white font-medium'
            }
            onClick={() => print()}
          >
            <PrinterIcon className={'w-8 h-8 stroke-current'} />
            Imprimer
          </button>
          <button
            className={
              'ring-gray-400 ring-2 rounded-full p-4 text-gray-600 flex items-center justify-center hover:bg-gray-600 hover:ring-gray-600 hover:text-white font-medium'
            }
            disabled={loading}
            onClick={async () => {
              await createPdf(currentUser.advancements[0]);
            }}
          >
            {loading ? (
              <>
                <Loading className={'animate-spin'} /> Creation du PDF
              </>
            ) : (
              <>
                <CloudArrowDownIcon className={'w-8 h-8 stroke-current'} />
                Télécharger en PDF
              </>
            )}
          </button>
        </div>
      ) : null}

      <img
        src={require('../assets/img/logo_full.png').default}
        className={'w-1/4'}
      />
      {currentUser ? (
        <>
          <h2 className={'font-oswald text-2xl'}>
            Bonjour{' '}
            <span className={'capitalize'}>{currentUser?.firstname}</span>,
            voici votre nouvelle programmation sur mesure pour la semaine du
          </h2>
          <h2 className={'font-oswald font-bold text-4xl text-teal-500 my-5'}>
            {format(addDays(new Date(), 0), 'dd MMMM', { locale: frLocale })} au{' '}
            {format(addDays(new Date(), 5), 'dd MMMM', { locale: frLocale })}
          </h2>
        </>
      ) : null}

      {week ? <Header week={week} /> : null}
      {week ? <Program week={week} /> : null}
      <Footer />
    </div>
  );
};

export default CreateProgram;
