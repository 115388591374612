import User from '../classes/User';

interface AppState {
  user: {
    isLoading: boolean;
    current?: User;
  };
}

const initialState: AppState = {
  user: {
    isLoading: false,
    current: null,
  },
};

export default initialState;
