import Stripe from 'stripe';
import Price from '../../classes/Price';
import User from '../../classes/User';
import { Instance } from '../../classes/API/API';
import Base from '../../classes/Base';
import Product from '../../classes/Product';

export default class Subscription extends Base {
  // Static
  static activeStatus = ['active', 'trialing'];
  static deactivatedStatus = [
    'canceled',
    'ended',
    'incomplete',
    'incomplete_expired',
    'past_due',
    'unpaid',
    'deactivated',
    'unknown',
    'deleted',
  ];

  prefix = 'sub';
  active = true;
  isLifetime = false;
  stripeId?: string;
  source?: 'stripe' | 'in-app-purchase' = 'stripe';
  status = 'unknown';
  collectionMethod?: string;
  currentPeriodStart?: Date;
  currentPeriodEnd?: Date;
  trialEnd?: Date;
  metadata?: Record<string, any>;
  stage?: string;
  price?: Price;
  product?: Product;
  user?: User;
  stripeEntity?: Stripe.Subscription;

  static async createIncompleteSubscription(
    user: User,
    priceId: string,
    paymentMethod: 'card' | 'paypal',
  ) {
    try {
      const { data } = await Instance(true).request.post<{
        message: string;
        subscription_id: string;
        client_secret: string;
      }>(`/v1/users/${user.hash}/subscriptions`, {
        payment_method: paymentMethod,
        price_id: priceId,
      });

      return data;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async subscribe(user: User, storeToken = true, source = 'customer') {
    try {
      const { data } = await Instance(true).request.post<{
        message: string;
        data: {
          subscription: Subscription;
          jwt: string;
        };
      }>(`/v1/subscriptions`, {
        user_id: user.hash,
        price_id: this.price.hash,
        product_id: this.product.hash,
        trial_end: this.trialEnd,
        metadata: this.metadata,
        source,
      });

      if (storeToken) {
        user.storeJwt(data.data.jwt);
      }
      return Object.assign(new Subscription(), data.data.subscription);
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  static async getAll(user: User): Promise<Subscription[]> {
    try {
      const { data } = await Instance(true).request.get<{
        message: string;
        data: {
          subscriptions: Subscription[];
        };
      }>(`/v1/subscriptions`, {
        params: {
          user_id: user.hash,
        },
      });
      return data.data.subscriptions;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async cancel(): Promise<boolean> {
    try {
      const { status } = await Instance(true).request.patch<{
        message: string;
        data: {
          subscriptions: Subscription[];
        };
      }>(`/v1/subscriptions/${this.hash}/cancel`);
      return status === 204;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async delete(): Promise<boolean> {
    try {
      const { status } = await Instance(true).request.delete<{
        message: string;
        data: {
          subscriptions: Subscription[];
        };
      }>(`/v1/subscriptions/${this.hash}`);
      return status === 204;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }
}
