import axios from 'axios';

const TOKEN_KEY = '@RJF::ApiToken';

const { CancelToken } = axios;

const Instance = (authenticated: boolean | 'maybe' = false) => {
  let cancel;
  const apiToken = window.localStorage.getItem(TOKEN_KEY);

  const headers: any = {};

  if (authenticated === true && apiToken) {
    headers.Authorization = `Bearer ${apiToken}`;
  } else if (authenticated === true && !apiToken) {
    throw new Error('No API Token for performing request');
  } else if (authenticated === 'maybe') {
    if (apiToken) {
      headers.Authorization = `Bearer ${apiToken}`;
    }
  }

  //headers['Content-Type'] = 'application/json';
  //headers['Accept'] = 'application/json';

  const request = axios.create({
    baseURL: process.env.API_URL,
    headers,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  /*request.interceptors.response.use((response) => {
    if (response.status === 401) {
      window.location.href =
        process.env.FRONT_URL + '/se-connecter?error=unauthenticated';
      return response;
    } else {
      return response;
    }
  });*/

  return { request, cancel };
};

export { Instance, TOKEN_KEY };
