import Base from './Base';
import { parseISO } from 'date-fns';

export default class Evaluation extends Base {
  difficulty!: number;
  intensity!: number;
  pain!: number;
  week!: number;
  day!: number;
  date: Date = new Date();

  constructor(
    difficulty?: number,
    intesity?: number,
    pain?: number,
    day?: number,
    week?: number,
  ) {
    super();
    this.difficulty = difficulty;
    this.intensity = intesity;
    this.pain = pain;
    this.date = new Date();
    this.week = week;
    this.day = day;
  }

  static fromJSON(object: Record<string, any>): Evaluation {
    const ev = Object.assign(new Evaluation(), object);
    ev.date = parseISO(object.date);
    return ev;
  }
}
