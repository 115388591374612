import React, { useEffect, useState } from 'react';

import {
  InformationCircleIcon,
  XMarkIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

enum AlertType {
  success,
  info,
  warning,
  alert,
}

interface AlertProps {
  type: AlertType;
  title?: string;
  body: string | JSX.Element;
  onClose?: () => void;
  width?: string;
}

const Alert = ({
  type,
  title,
  body,
  onClose,
  width = 'max-w-max',
}: AlertProps) => {
  useEffect(() => {
    updateStyle(type);
  }, [type]);

  const [style, setStyle] = useState<{
    backgroundColor: string;
    color: string;
    borderColor: string;
    icon?: number | JSX.Element;
  } | null>(null);

  const updateStyle = (type: AlertType) => {
    const style = {
      backgroundColor: 'bg-sky-600',
      color: 'text-sky-200',
      borderColor: 'border-sky-800',
      icon: null,
    };

    style.icon = (
      <XCircleIcon className={`h-8 w-8 ${style.color}`} aria-hidden="true" />
    );

    switch (type) {
      case AlertType.alert:
        style.backgroundColor = 'bg-red-100';
        style.color = 'text-red-700';
        style.borderColor = 'border-red-700';
        style.icon = (
          <XCircleIcon
            className={`h-8 w-8 ${style.color}`}
            aria-hidden="true"
          />
        );
        break;
      case AlertType.info:
        style.backgroundColor = 'bg-blue-100';
        style.color = 'text-blue-800';
        style.borderColor = 'border-blue-800';
        style.icon = (
          <InformationCircleIcon
            className={`h-8 w-8 ${style.color}`}
            aria-hidden="true"
          />
        );
        break;
      case AlertType.success:
        style.backgroundColor = 'bg-green-100';
        style.color = 'text-green-500';
        style.borderColor = 'border-green-500';
        style.icon = (
          <CheckCircleIcon
            className={`h-8 w-8 ${style.color}`}
            aria-hidden="true"
          />
        );
        break;

      case AlertType.warning:
        style.backgroundColor = 'bg-amber-100';
        style.color = 'text-amber-500';
        style.borderColor = 'border-amber-500';
        style.icon = (
          <ExclamationCircleIcon
            className={`h-8 w-8 ${style.color}`}
            aria-hidden="true"
          />
        );
        break;
    }

    setStyle(style);
  };

  return style ? (
    <>
      <div
        className={`flex justify-between items-center ${width} rounded-md ${style.backgroundColor} p-4 my-4 border border-solid ${style.borderColor}`}
      >
        <div className="flex items-center">
          <div className="flex-shrink-0">{style.icon}</div>
          <div className="ml-3">
            {title && (
              <h3 className={`text-base font-bold ${style.color}`}>{title}</h3>
            )}
            <div
              className={`${title ? 'mt-2' : 'mt-0'} text-base ${style.color}`}
            >
              {body}
            </div>
          </div>
        </div>
        {onClose ? (
          <span
            onClick={onClose}
            className={`text-gray-600 cursor-pointer ml-4 text-xl`}
          >
            &times;
          </span>
        ) : null}
      </div>
      {/*<div
        className={`${style.borderColor} border border-2 ${style.backgroundColor} rounded p-4 my-4 mr-2`}
      >
        {title ? (
          <h2 className={`font-semibold text-l ${style.color} mb-2`}>
            {title}
          </h2>
        ) : null}
        <div className={`w-full flex flex-column items-center justify-between`}>
          <p className={`${style.color} text-base font-normal`}>{body}</p>

        </div>
      </div>*/}
    </>
  ) : null;
};

export default Alert;
export { AlertType };
