import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Training from '../classes/Training';

type TrainingsState = {
  isLoading: boolean;
  allLoading: boolean;
  current: Training;
  trainings: Training[];
  error: string;
};
const initialState: TrainingsState = {
  isLoading: false,
  allLoading: false,
  current: null,
  trainings: [],
  error: null,
};

const trainingSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<{ all: boolean }>) {
      if (action.payload.all) {
        state.allLoading = !state.allLoading;
      } else {
        state.isLoading = !state.isLoading;
      }
    },
    fetchTrainingsSuccess(state, action: PayloadAction<Training[]>) {
      state.trainings = action.payload;
    },
    fetchOneTrainingSuccess(state, action: PayloadAction<Training>) {
      state.current = action.payload;
    },
    fetchTrainingsError(
      state,
      action: PayloadAction<{ error: string; code: number }>,
    ) {
      state.error = `Erreur : ${action.payload.error} (${action.payload.code})`;
    },
    createTrainingSuccess(state, action: PayloadAction<Training>) {
      state.trainings.push(action.payload);
    },
    createTrainingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    editTrainingSuccess(state, action: PayloadAction<Training>) {
      const index = state.trainings.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.trainings.splice(index, 1, action.payload);
    },
    editTrainingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    deleteTrainingSuccess(state, action: PayloadAction<Training>) {
      const index = state.trainings.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.trainings.splice(index, 1);
    },
    deleteTrainingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export default trainingSlice.reducer;
export const {
  startLoading,
  createTrainingSuccess,
  createTrainingError,
  fetchTrainingsSuccess,
  fetchOneTrainingSuccess,
  fetchTrainingsError,
  editTrainingSuccess,
  editTrainingError,
  deleteTrainingSuccess,
  deleteTrainingError,
} = trainingSlice.actions;
