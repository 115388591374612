// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/julien.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignupForm{background:#fff}.SignupForm-WithJulien{background:linear-gradient(to bottom, transparent 0%, white 70%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top left no-repeat,#fff;background-size:490px;padding-top:420px}", "",{"version":3,"sources":["webpack://./assets/scss/SignupForm.sass"],"names":[],"mappings":"AAAA,YACE,eAAA,CAEF,uBACE,gIAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":[".SignupForm\n  background: white\n\n.SignupForm-WithJulien\n  background: linear-gradient(to bottom, transparent 0%, white 70%), url('../img/julien.png') top left no-repeat, white\n  background-size: 490px\n  padding-top: 420px"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
