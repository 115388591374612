import { isDev } from '../classes/Environement';
import { configureStore, EnhancedStore, Action } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import rootReducer, { RootState } from '../reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';

/* COMMON */
let devTools = false;

const history = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);

let middlewares = [thunkMiddleware, reactRouterMiddleware];

/* DEV */

if (isDev) {
  devTools = true;
  middlewares = middlewares.concat([
    reduxImmutableStateInvariant(),
    //loggerMiddleware,
  ]);
}

let store: EnhancedStore;

const configureAppStore = (preloadedState: any) => {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    preloadedState,
    devTools,
    enhancers: [],
  });

  return store;
};

//@ts-ignore
if (isDev && module.hot) {
  //@ts-ignore
  module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default configureAppStore;
