import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Training from '../classes/Training';
import Video from '../classes/Video';
import TrainingSession from '../classes/TrainingSession';

type SessionState = {
  isLoading: boolean;
  sessions: TrainingSession[];
  error: string;
};
const initialState: SessionState = {
  isLoading: false,
  sessions: [],
  error: null,
};

const sessions: TrainingSession[] = [];

const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = !state.isLoading;
    },
    fetchSessionSuccess(state, action: PayloadAction<TrainingSession[]>) {
      state.sessions = action.payload;
    },
    createSessionSuccess(state, action: PayloadAction<TrainingSession>) {
      state.sessions.push(action.payload);
    },
    editSessionSuccess(state, action: PayloadAction<TrainingSession>) {
      const index = state.sessions.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.sessions.splice(index, 1, action.payload);
    },
    deleteSessionSuccess(state, action: PayloadAction<TrainingSession>) {
      const index = state.sessions.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.sessions.splice(index, 1);
    },
    sessionError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export default sessionSlice.reducer;
export const {
  startLoading,
  fetchSessionSuccess,
  createSessionSuccess,
  editSessionSuccess,
  deleteSessionSuccess,
  sessionError,
  clearError,
} = sessionSlice.actions;
