import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './../assets/globals/style.css';
import '../assets/scss/SignupForm.sass';

import Input from '../components/forms/Input';
import Layout from '../components/Layout';
import User from '../classes/User';

import { identifySegment } from '../hooks/useSegment';
import SegmentEvents from '../constants/SegmentEvents';
import useSearchParams from '../hooks/useSearchParams';
import useUser from '../hooks/useUser';
import { Event } from '../constants/SegmentEvents';

const ChallengeConnect = () => {
  const { user, fetchUser } = useUser();
  const navigate = useHistory();

  const search = useSearchParams<{ token?: string; firstname: string }>();
  useEffect(() => {
    if (user) {
      navigate.push('/');
      identifySegment(user);
      Event.LOGIN_AS_PROSPECT.track();
    }
  }, [user]);

  useEffect(() => {
    if (search.token) {
      const u = new User();
      u.storeJwt(search.token);
      fetchUser();
    }
  }, [search.token]);

  return (
    <Layout>
      <>
        <h2 className={'text-2xl font-medium mb-6 text-gray-600'}>
          Bonjour {search.firstname}
        </h2>
        <h3 className={'text-xl font-medium mb-6 text-black'}>
          Un petit instant, nous préparons votre challenge sur mesure !
        </h3>

        <hr />
      </>
    </Layout>
  );
};

export default ChallengeConnect;
