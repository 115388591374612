import { Instance } from './API/API';
import qs from 'qs';
import File from './File';

export default class Video extends File {
  prefix = 'vid';

  muxPlaybackId!: string;
  muxAssetId!: string;
  duration?: number;
  thumbnail!: string;
  status!: string;
  videoFile!: File;

  constructor(file?: string) {
    super(file);
  }

  static async getAll(): Promise<Video[]> {
    const response = await Instance(true).request.get<{
      success: boolean;
      videos: Video[];
    }>('/v1/videos');

    return response.data.videos.map((v) => Object.assign(new Video(null), v));
  }

  async get() {
    const { data } = await Instance('maybe').request.get<{
      success: boolean;
      video: Video;
    }>('/v1/videos/' + this.hash);

    Object.keys(data.video).map((key) => {
      this[key] = data.video[key];
    });
  }

  public static async get(hash: string): Promise<Video> {
    const { data } = await Instance('maybe').request.get<{
      success: boolean;
      video: Video;
    }>('/v1/videos/' + hash);

    return Object.assign(new Video(), data.video);
  }

  getHLS = (): string => {
    return `https://stream.mux.com/${this.muxPlaybackId}.m3u8`;
  };

  async getUploadURL(): Promise<void> {
    await super.getUploadURL(true);
  }

  getSources() {
    return [
      {
        src: this.getHLS(),
        type: 'application/x-mpegURL',
      },
    ];
  }

  async sendToMux() {
    const { data } = await Instance(true).request.post<{
      video: Video;
      info: Record<string, any>;
    }>(`/v1/videos/${this.hash}/mux`);

    this.duration = data.video.duration;
    this.muxPlaybackId = data.video.muxPlaybackId;
    this.muxAssetId = data.video.muxAssetId;
  }

  getThumbnail = (
    type: 'png' | 'jpg' = 'png',
    width?: number,
    height?: number,
    time = 1,
    format = 'smartcrop',
  ): string => {
    const options = { width, height, time, format };
    const optionsString = qs.stringify(options);
    return `https://image.mux.com/${this.muxPlaybackId}/thumbnail.${type}${
      Object.keys(options).length ? `?${optionsString}` : ''
    }`;
  };

  async delete() {
    return Instance(true).request.delete<{
      video: Video;
    }>(`/v1/videos/${this.hash}`);
  }

  static fromJson(json: Record<string, any>): Video {
    return Object.assign(new Video(), json);
  }
}
