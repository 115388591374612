import {
  IIngredientsList,
  IIngredientsListFields,
} from '../../interfaces/contentfulModels';
import React, { useEffect, useState } from 'react';
import contentfulClient from '../../utils/contentfulClient';
import { Entry } from 'contentful';

const IngredientList = ({
  ingredientList,
}: {
  ingredientList: IIngredientsList;
}) => {
  const [list, setList] = useState<IIngredientsListFields | undefined>();
  const [guests, setGuests] = useState(0);

  useEffect(() => {
    contentfulClient
      .getEntry<IIngredientsListFields>(ingredientList.sys.id)
      .then((data) => {
        setList(data.fields);
        setGuests(data.fields.guests);
      });
  }, []);

  return (
    <div className={'w-full'}>
      <div
        className={
          'grid grid-cols-3 divide-x-2 divide-content-secondary divide border-2 border-content-secondary w-fit rounded font-bold'
        }
      >
        <button
          className={
            'py-2 px-4 text-teal-600 hover:bg-content-secondary hover:text-white font-bold'
          }
          onClick={() => {
            const g = guests;
            setGuests(Math.max(1, g - 1));
          }}
        >
          -
        </button>
        <button className={'py-2 px-6 text-content-secondary font-bold'}>
          Pour {guests} {guests > 1 ? 'personnes' : 'personne'}
        </button>
        <button
          className={
            'py-2 px-6 text-teal-600 hover:bg-content-secondary hover:text-white  font-bold'
          }
          onClick={() => {
            const g = guests;
            setGuests(g + 1);
          }}
        >
          +
        </button>
      </div>
      <div className={'flex flex-wrap gap-2 mt-6'}>
        {list?.ingredients.map((ingredient) => {
          const qty =
            (ingredient.fields.quantity || 1) / (list?.guests / guests);
          return (
            <div
              key={ingredient.sys.id}
              className={
                'font-sans text-content-primary text-sm font-semibold flex flex-col gap-2 items-center'
              }
            >
              <div className={'shadow bg-white p-4 rounded h-24 w-24'}>
                {ingredient.fields.image && (
                  <img
                    src={[
                      'https:',
                      ingredient.fields.image.fields.file.url,
                    ].join('')}
                    alt={'icon'}
                  />
                )}
              </div>
              {[
                qty,
                ingredient.fields.unity == 'unit'
                  ? undefined
                  : ingredient.fields.unity,
                '\n',
                ingredient.fields.item,
              ]
                .join(' ')
                .trimEnd()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IngredientList;
