import { createClient } from 'contentful';
import {
  Options,
  RenderMark,
  RenderNode,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { children } from 'react-markdown';
import MuxContentfulVideoPlayer from '../components/MuxContentfulVideoPlayer';
import { IVideoContent } from '../interfaces/contentfulModels';
import IngredientList from '../components/IngredientList/IngredientList';
import { QuoteIcon } from '../assets/img/icons';
import ReceipeTime from '../components/ReceipeTime/ReceipeTime';

const contentfulClient = createClient({
  host: process.env.CONTENTFUL_CONTENT_HOST as string,
  space: process.env.CONTENTFUL_WORKSPACE_ID as string,
  accessToken: process.env.CONTENTFUL_CONTENT_KEY as string,
  //environment: process.env.CONTENTFUL_ENV as string,
});

export const titleRichTextOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (node, children) => (
    <p className={'font-sans text-base leading-loose my-1'}>{children}</p>
  ),
  [BLOCKS.HEADING_1]: (node, children) => (
    <h1 className={'font-outfit font-bold text-4xl'}>{children}</h1>
  ),
  [BLOCKS.HEADING_2]: (node, children) => (
    <>
      <hr className={'w-1/3 border-content-secondary border-1 mt-4'} />
      <h2 className={'font-outfit text-3xl text-content-secondary'}>
        {children}
      </h2>
    </>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <h3 className={'font-outfit font-bold text-xl text-content-primary'}>
      {children}
    </h3>
  ),
  [BLOCKS.OL_LIST]: (node, children) => (
    <ol className={'list-decimal'}>{children}</ol>
  ),
  [BLOCKS.UL_LIST]: (node, children) => (
    <ul className={'list-disc'}>{children}</ul>
  ),
  [BLOCKS.QUOTE]: (node, children) => (
    <div
      className={'bg-white shadow text-base font-medium font-sans py-6 px-8'}
    >
      <div
        className={
          'bg-content-secondary text-white rounded-full w-fit h-fit p-3 m-auto mb-4'
        }
      >
        <QuoteIcon className={'w-6 h-6'} />
      </div>
      {children}
    </div>
  ),
  [BLOCKS.HR]: (node, children) => (
    <div className={'m-auto w-3/4 flex flex-row rounded-full'}>
      <hr
        className={'w-1/2 rounded-l-full border-content-secondary border-2'}
      />
      <hr className={'w-1/2 rounded-r-full border-content-primary border-2'} />
    </div>
  ),
};

export const richTextOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <strong className={'font-sans text-base'}>{text}</strong>
    ),
    [MARKS.ITALIC]: (text) => (
      <em className={'font-sans italic text-md'}>{text}</em>
    ),
    [MARKS.CODE]: (text) => <code className={'font-mono'}>{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <div
          className={
            'bg-amber-400 w-full rounded shadow text-center font-bold py-2 hover:shadow-lg'
          }
        >
          <a
            href={node.data.uri}
            target={'_blank'}
            rel={'noreferrer'}
            className={''}
          >
            {children}
          </a>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const contentId = node.data.target.sys.contentType?.sys.id;

      switch (contentId) {
        case 'video_content':
          const video = node.data.target as IVideoContent;
          return <MuxContentfulVideoPlayer video={video} />;
        case 'ingredients_list':
          return <IngredientList ingredientList={node.data.target} />;
        case 'nutritionReceipeTime':
          return <ReceipeTime receipeTime={node.data.target} />;
        default:
          return <div />;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.url.indexOf('images') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <img
              className={'w-2/3 items-center'}
              src={node.data.target.fields.file.url}
            />
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('videos') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <video
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </video>
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('mp3') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <audio
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </audio>
          </div>
        );
      } else {
        return (
          <>
            <p>BLOCKS.EMBEDDED_ASSET</p>
            {JSON.stringify(node)}
            {JSON.stringify(children)}
          </>
        );
      }
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return (
        <div className={'ml-2'}>
          <ol className={'list-outside'}>{children}</ol>
        </div>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <div className={'ml-4'}>
          <ul className={'list-outside'}>{children}</ul>
        </div>
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li className={'ml-2 list-outside text-content-primary'}>{children}</li>
      );
    },
    ...titleRichTextOptions,
  },
};

export default contentfulClient;
