import React from 'react';
import './Loader.sass';

const Loader = ({
  color = 'turquoise',
}: {
  color?: 'turquoise' | 'white' | 'black';
}) => {
  return <div className={`loader ${color}`} />;
};
export default Loader;
