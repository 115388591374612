import Base from './Base';
import { Instance } from './API/API';

import ProgramWeek from './ProgramWeek';
import TrainingSession from './TrainingSession';

export default class ProgramDay extends Base {
  week!: ProgramWeek;
  number = 0;
  description = '';
  compulsorySession: TrainingSession | string;
  optionnalSession: TrainingSession | string;

  async getAll(): Promise<ProgramWeek[]> {
    const response = await Instance(true).request.get<{
      success: boolean;
      weeks: ProgramWeek[];
    }>(`/v1/programs/${this.week.program.hash}/weeks/${this.week.hash}/days`);

    return response.data.weeks.map((p) =>
      Object.assign(new ProgramWeek(), { ...p }),
    );
  }

  async create(): Promise<void> {
    const { data } = await Instance(true).request.post<{
      success: boolean;
      day: ProgramDay;
    }>(`/v1/programs/${this.week.program.hash}/weeks/${this.week.hash}/days`, {
      ...this,
    });

    this.id = data.day.id;
    this.hash = data.day.hash;
    this.compulsorySession = Object.assign(new TrainingSession(), {
      ...(data.day.compulsorySession as TrainingSession),
    });
    this.optionnalSession = Object.assign(new TrainingSession(), {
      ...(data.day.optionnalSession as TrainingSession),
    });
    this.createdAt = data.day.createdAt;
    this.updatedAt = data.day.updatedAt;
  }

  async update(): Promise<void> {
    const { data } = await Instance(true).request.put<{
      success: boolean;
      day: ProgramDay;
    }>(
      `/v1/programs/${this.week.program.hash}/weeks/${this.week.hash}/days/${this.hash}`,
      {
        ...this,
      },
    );
    this.updatedAt = data.day.updatedAt;
  }

  async delete(): Promise<any> {
    return Instance(true).request.delete(
      `/v1/programs/${this.week.program.hash}/weeks/${this.week.hash}/days/${this.hash}`,
    );
  }

  static fromJson(json: Record<string, any>) {
    const day = Object.assign(new ProgramDay(), json);
    day.compulsorySession = TrainingSession.fromJson(json.compulsorySession);
    day.optionnalSession = json.optionnalSession
      ? TrainingSession.fromJson(json.optionnalSession)
      : null;

    return day;
  }
}
