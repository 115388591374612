import User from '../classes/User';

const loadSegment = () => {
  window.analytics.load(process.env.SEGMENT_WRITE_KEY);
};

const identifySegment = (user: User) => {
  window.analytics.identify(user.hash, {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email.toLowerCase(),
  });
};

const anonymousIdentifySegment = (email?: string) => {
  window.analytics.identify({
    email: email.toLowerCase(),
  });
};

export { loadSegment, identifySegment, anonymousIdentifySegment };
