import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProgramWeek from '../classes/ProgramWeek';

type ProgramState = {
  isLoading: boolean;
  weeks: ProgramWeek[];
  error: string;
};
const initialState: ProgramState = {
  isLoading: false,
  weeks: [],
  error: null,
};

const weeksSlice = createSlice({
  name: 'weeks',
  initialState,
  reducers: {
    startWeeksLoading(state) {
      state.isLoading = !state.isLoading;
    },
    fetchWeeksSuccess(state, action: PayloadAction<ProgramWeek[]>) {
      state.weeks = action.payload;
    },
    fetchOneWeekSuccess(state, action: PayloadAction<ProgramWeek>) {
      const index = state.weeks.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.weeks.splice(
        index >= 0 ? index : state.weeks.length,
        1,
        action.payload,
      );
    },
    createWeekSuccess(state, action: PayloadAction<ProgramWeek>) {
      state.weeks.push(action.payload);
    },
    updateWeekSuccess(state, action: PayloadAction<ProgramWeek>) {
      const index = state.weeks.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.weeks.splice(index, 1, action.payload);
    },
    deleteWeekSuccess(state, action: PayloadAction<ProgramWeek>) {
      const index = state.weeks.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.weeks.splice(index, 1);
    },
    weekError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearWeekError(state) {
      state.error = null;
    },
  },
});

export default weeksSlice.reducer;
export const {
  startWeeksLoading,
  fetchWeeksSuccess,
  createWeekSuccess,
  updateWeekSuccess,
  deleteWeekSuccess,
  fetchOneWeekSuccess,
  weekError,
  clearWeekError,
} = weeksSlice.actions;
