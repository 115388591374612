import Base from './Base';
import { Instance } from './API/API';
import ProgramWeek from './ProgramWeek';
import ProgramDay from './ProgramDay';
import Training from './Training';

export enum ProgramLevel {
  Easy = 'Easy',
  Confirmed = 'Confirmed',
  Advanced = 'Advanced',
}

export enum ProgramType {
  Club = 'Club',
  Challenge = 'Challenge',
}

export default class Program extends Base {
  slug!: string;
  name!: string;
  level: ProgramLevel = ProgramLevel.Easy;
  description: string;
  isChallenge: boolean;
  isVisibleByUsers: boolean;
  weeks: ProgramWeek[];

  static async getAll(): Promise<Program[]> {
    const response = await Instance(true).request.get<{
      success: boolean;
      programs: Program[];
    }>('/v1/programs');

    return response.data.programs.map((p) =>
      Object.assign(new Program(), { ...p, level: p.level as ProgramLevel }),
    );
  }

  static async getVideo(
    hash: string,
    week: string,
    day: string,
  ): Promise<ProgramDay> {
    const { data } = await Instance(true).request.get<{
      success: boolean;
      data: { day: ProgramDay; training: Training };
    }>(`/v1/programs/${hash}/weeks/${week}/days/${day}`);
    return data.data.day;
  }

  static async getOne(hash: string, includeDetails: boolean): Promise<Program> {
    const { data } = await Instance(true).request.get<{
      success: boolean;
      program: Program;
    }>('/v1/programs/' + hash, {
      params: { includeDetails },
    });
    return Object.assign(new Program(), {
      ...data.program,
      level: data.program.level as ProgramLevel,
    });
  }

  async create() {
    return Instance(true).request.post(`/v1/programs`, {
      ...this,
    });
  }

  async update() {
    return Instance(true).request.put(`/v1/programs/${this.hash}`, { ...this });
  }

  async delete(): Promise<any> {
    return Instance(true).request.delete(`/v1/programs/${this.hash}`);
  }

  static fromJson(object: Record<string, any>) {
    const program = Object.assign(new Program(), { ...object });
    program.weeks = object.weeks?.map((w) => ProgramWeek.fromJson(w));
    return program;
  }
}
