import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Training from '../classes/Training';
import Video from '../classes/Video';

type TrainingsState = {
  isLoading: boolean;
  videos: Video[];
  error: string;
};
const initialState: TrainingsState = {
  isLoading: false,
  videos: [],
  error: null,
};

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = !state.isLoading;
    },
    fetchVideoSuccess(state, action: PayloadAction<Video[]>) {
      state.videos = action.payload;
    },
    deleteVideoSuccess(state, action: PayloadAction<Video>) {
      const index = state.videos.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.videos.splice(index, 1);
    },
    videoError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearVideoError(state) {
      state.error = null;
    },
  },
});

export default videosSlice.reducer;
export const {
  startLoading,
  fetchVideoSuccess,
  deleteVideoSuccess,
  videoError,
  clearVideoError,
} = videosSlice.actions;
