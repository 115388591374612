import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Input from '../components/forms/Input';
import Layout from '../components/Layout';

import User from '../classes/User';
import {
  Enveloppe,
  Key,
  User as UserIcon,
  CommunityIcon,
} from '../assets/img/icons';

import Alert, { AlertType } from '../components/Alert';
import Button from '../components/forms/Button';

import { useAppSelector } from '../hooks/redux';
import { signup } from '../actions/user.action';
import { clearErrors } from '../reducers/user.reducer';

import trackSegment, { identifySegment } from '../hooks/useSegment';
import { Event } from '../constants/SegmentEvents';

import useSearchParams from '../hooks/useSearchParams';

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

enum Field {
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}

const Signup = (): JSX.Element => {
  const dispatch = useDispatch();

  const loading = useAppSelector((state) => state.user.isLoading);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.user.error);

  const [user, setUser] = useState(new User());
  const [enabled, setEnabled] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [popup, setPopup] = useState<JSX.Element | null>(null);
  const [checkResponse, setCheckResponse] = useState<
    CheckAccountResponse | undefined
  >();
  const [inputSponsorVisible, setInputSponsorVisible] =
    useState<boolean>(false);

  const navigation = useHistory();
  const search = useSearchParams<{ parrain: string }>();

  useEffect(() => {
    if (error) {
      setPopup(
        <Alert
          type={AlertType.alert}
          body={error}
          title={'Une erreur est survenue'}
          onClose={() => dispatch(clearErrors())}
          width={'w-full'}
        />,
      );
    } else if (currentUser) {
      //@ts-ignore
      identifySegment(currentUser);
      Event.SIGN_UP.track();
      navigation.push('/');
    } else {
      setPopup(null);
    }
  }, [currentUser, error]);

  useEffect(() => {
    if (search.parrain) {
      updateFields(search.parrain, 'sponsor');
    }
  }, [search]);

  const updateFields = (value: string, field: keyof User) => {
    const _user = user;

    switch (field) {
      case 'firstname':
        _user.firstname = value;
        break;
      case 'lastname':
        _user.lastname = value;
        break;
      case 'email':
        _user.email = value;
        break;
      case 'password':
        _user.password = value;
        _user.confirmPassword = value;
        break;
      case 'sponsor':
        _user.sponsor = value;
        break;
      /*case Field.confirmPassword:
        _user.confirmPassword = value;
        break;*/
    }

    setUser(_user);
  };

  const signUp = () => {
    if (!tosAccepted) {
      setPopup(
        <Alert
          type={AlertType.alert}
          body={
            "Vous devez accepter les conditions générales de vente et d'utilisation"
          }
          title={'Une erreur est survenue'}
          onClose={() => dispatch(clearErrors())}
          width={'w-full'}
        />,
      );
      return;
    }

    const _user = user;
    setUser(_user);
    dispatch(signup(user));
  };

  const facebookSignUp = (facebookResponse) => {
    const _user = user;
    _user.firstname = facebookResponse.name;
    _user.email = facebookResponse.email;
    _user.facebookId = facebookResponse.id;

    setUser(_user);
    signUp();
  };

  const appleSignUp = (appleResponse: AppleResponse) => {
    const _user = user;

    if (appleResponse.error) {
      return;
    }

    if (appleResponse.user) {
      _user.firstname = appleResponse.user.name.firstName;
      _user.lastname = appleResponse.user.name.lastName;
      _user.email = appleResponse.user.email;
    }
    if (appleResponse.authorization) {
      _user.apple_id = appleResponse.authorization.id_token;
    }

    setUser(_user);

    signUp();
  };

  const googleSignUp = (googleResponse, res) => {
    const _user = user;
    _user.firstname = googleResponse.givenName;
    _user.lastname = googleResponse.lastName;
    _user.email = googleResponse.email;
    _user.googleId = googleResponse.googleId;
    setUser(_user);
    signUp();
  };

  const checkEmail = async () => {
    const checks = await user.checkAccount();
    setCheckResponse(checks);
  };

  const CheckResponses: FC = () => {
    if (checkResponse.error) {
      setEnabled(false);
      return (
        <span className={'font-medium text-red-600 flex gap-1 items-center'}>
          <ExclamationTriangleIcon className={'w-5 h-5'} />{' '}
          {checkResponse.error}
        </span>
      );
    } else if (checkResponse.data.suggestion) {
      setEnabled(true);
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationCircleIcon className={'w-5 h-5'} /> Hum, ne serait-ce pas
          plutôt{' '}
          <strong>
            {checkResponse.data.local}@{checkResponse.data.suggestion}
          </strong>{' '}
          ?
        </span>
      );
    } else if (
      checkResponse.data.checks.domain.is_suspected_disposable_address
    ) {
      setEnabled(false);
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationTriangleIcon className={'w-5 h-5 shrink-0'} /> Aïe, il
          semblerait que ce soit une addresse email jetable. Vous ne pouvez pas
          vous inscrire avec cette addresse !
        </span>
      );
    } else if (
      checkResponse.data.verdict === 'Risky' ||
      checkResponse.data.verdict === 'Invalid'
    ) {
      setEnabled(false);
      return (
        <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
          <ExclamationCircleIcon className={'w-5 h-5 shrink-0'} /> Attention,
          vous pourriez avoir des difficultés à recevoir nos emails.
        </span>
      );
    } else {
      setEnabled(true);
      return <div />;
    }
  };

  return (
    <Layout>
      <div className={'sm:overflow-y-visible'}>
        <h2 className={'text-2xl font-medium mb-6 text-gray-600 font-oswald'}>
          Inscrivez vous et bénéficiez de notre Challenge Gratuit !
        </h2>
        {popup}
        <div className={'flex flex-col'}>
          {/*<div className={'flex w-full xs:flex-col sm:flex-col xl:flex-row'}>
          <Input
            leftComponent={
              <UserIcon className={'stroke-current text-gray-400'}/>
            }
            type={'text'}
            autocomplete={'given-name'}
            placeholder={'Votre prénom'}
            onChange={(value) => updateFields(value, Field.firstName)}
            required
          />
          <Input
            type={'text'}
            autocomplete={'family-name'}
            placeholder={'Votre nom'}
            onChange={(value) => updateFields(value, Field.lastName)}
            required
          />
        </div>*/}
          <Input
            leftComponent={
              <UserIcon
                className={
                  'stroke-current text-gray-400 group-focus:text-teal-600'
                }
              />
            }
            type={'text'}
            autocomplete={'given-name'}
            placeholder={'Votre prénom'}
            onChange={(value) => updateFields(value, 'firstname')}
            required
          />
          <Input
            leftComponent={
              <Enveloppe
                className={
                  'stroke-current text-gray-400 group-focus:text-teal-600'
                }
              />
            }
            type={'text'}
            autocomplete={'email'}
            placeholder={'Votre email'}
            onChange={(value) => updateFields(value, 'email')}
            onBlur={async () => {
              await checkEmail();
            }}
            required
          />
          {checkResponse && <CheckResponses />}
          <Input
            leftComponent={
              <Key
                className={
                  'stroke-current text-gray-400 focus-within:text-teal-600'
                }
              />
            }
            type={'password'}
            autocomplete={'new-password'}
            placeholder={'Choisir un mot de passe'}
            onChange={(value) => updateFields(value, 'password')}
            required
          />
          {search.parrain || inputSponsorVisible ? (
            <Input
              leftComponent={
                <CommunityIcon className={'stroke-current text-gray-400'} />
              }
              type={'text'}
              placeholder={'Code parrain'}
              value={search.parrain}
            />
          ) : null}
          <div className={'flex items-center gap-2'}>
            <input
              type={'checkbox'}
              onChange={(e) => setTosAccepted(e.target.checked)}
              className={
                'border border-slate-500 rounded text-teal-600 hover:ring-teal-600 focus:ring-teal-600'
              }
            />{' '}
            <div
              className={
                'inline-flex flex-wrap gap-1 text-gray-600 text-sm font-base'
              }
            >
              En m'inscrivant, j'accepte
              <a
                href={'/legal/cgv'}
                className={
                  'font-medium underline decoration-teal-600 underline-offset-2'
                }
              >
                les CGVU
              </a>
              et
              <a
                href={'/legal/privacy'}
                className={
                  'font-medium underline underline-offset-2 decoration-teal-600'
                }
              >
                la Politique de confidentialité
              </a>
              de Rester Jeune.
            </div>
          </div>
        </div>
        {/*<div className={'flex w-full xs:flex-col sm:flex-col xl:flex-row'}>
          <Input
            leftComponent={<Key className={'stroke-current text-gray-400'} />}
            type={'password'}
            autocomplete={'new-password'}
            placeholder={'Choisir un mot de passe'}
            onChange={(value) => updateFields(value, Field.password)}
            required
          />
          <Input
            leftComponent={<Key className={'stroke-current text-gray-400'} />}
            type={'password'}
            autocomplete={'new-password'}
            placeholder={'Confirmer votre mot de passe'}
            onChange={(value) => updateFields(value, Field.confirmPassword)}
            required
          />
        </div>*/}

        <Button
          otherClasses={
            'text-white px-12 mt-6 mb-6 w-full rounded-lg font-oswald'
          }
          backgroundColor={'bg-teal-600 hover:bg-teal-700'}
          onClick={signUp}
          disabled={loading || !enabled}
          loading={loading}
          loadingText={'Création de votre compte'}
        >
          <span className={'w-full text-lg'}> S'inscrire </span>
        </Button>

        {/*<div className={'flex items-center gap-4 text-sm mb-4'}>
          <hr className={'flex-1'} />
          Inscrivez-vous avec votre réseau social préféré
          <hr className={'flex-1'} />
        </div>
        <div
          className={
            'flex justify-between xs:flex-col sm:flex-col xl:flex-row gap-1 mb-2'
          }
        >
          <FacebookInButton
            showText={false}
            rounded={false}
            buttonText={'Inscription avec Facebook'}
            facebookResponseHandler={(res) => facebookSignUp(res)}
          />
          <AppleLoginButton
            callback={appleSignUp}
            type={'continue'}
            showText={false}
          />

          <GoogleSignInButton
            showText={false}
            rounded={false}
            buttonText={`Inscription avec Google`}
            handleSuccess={(res: GoogleLoginResponse) =>
              googleSignUp(res.profileObj, res)
            }
            handleFailure={(err) => console.error(err)}
          />
        </div>
        <hr className={'mb-4 mt-4'} />*/}
        <div className={'text-sm mt-4'}>
          J'ai déjà un compte :{' '}
          <Link to={'/se-connecter'} className={'font-bold text-teal-800'}>
            Je me connecte
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
