import Base from './Base';
import { Instance } from './API/API';
import Program from './Program';
import ProgramDay from './ProgramDay';
import APIReponse from './API/APIResponse';

export enum WeekKeys {
  number,
  title,
  description,
}

export default class ProgramWeek extends Base {
  program?: Program;
  programHash: string;
  slug!: string;
  number = 0;
  title?: string;
  description?: string;
  days: ProgramDay[];

  static async getAll(program: Program): Promise<ProgramWeek[]> {
    const response = await Instance(true).request.get<{
      success: boolean;
      weeks: ProgramWeek[];
    }>(`/v1/programs/${program.hash}/weeks`);

    return response.data.weeks.map((p) => {
      delete p.program;
      return Object.assign(new ProgramWeek(), { ...p });
    });
  }

  static async get(programId: string, week: number): Promise<ProgramWeek> {
    const response = await Instance(true).request.get<
      APIReponse<{ week: ProgramWeek }>
    >(`/v1/programs/${programId}/weeks/${week}`);
    return ProgramWeek.fromJson(response.data.data.week);
  }

  async create(): Promise<void> {
    const { data } = await Instance(true).request.post<{
      success: boolean;
      week: ProgramWeek;
    }>(`/v1/programs/${this.program.hash}/weeks`, {
      ...this,
    });

    this.slug = data.week.slug;
    this.id = data.week.id;
    this.hash = data.week.hash;
    this.days = [];
    this.number = data.week.number;
    this.createdAt = data.week.createdAt;
    this.updatedAt = data.week.updatedAt;
  }

  async update(): Promise<void> {
    const { data } = await Instance(true).request.patch(
      `/v1/programs/${this.program.hash}/weeks/${this.hash}`,
      {
        ...this,
      },
    );
    this.number = data.week.number;
    this.updatedAt = data.week.updatedAt;
  }

  async delete(): Promise<any> {
    return Instance(true).request.delete(
      `/v1/programs/${this.program.hash}/weeks/${this.hash}`,
    );
  }

  static fromJson(json: Record<string, any>): ProgramWeek {
    const week = Object.assign(new ProgramWeek(), json);
    week.days = json.days.map((d) => ProgramDay.fromJson(d));
    return week;
  }
}
