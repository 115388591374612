import { Instance } from './API/API';
import Base from './Base';
import axios from 'axios';
import sanitize from '../utils/sanitize';
import APIError from './APIError';

class File extends Base {
  type?: string;
  fileName?: string;
  name?: string;
  file?: any;
  downloadUrl: string;
  url: string;
  isPublic?: boolean = false;
  private uploadUrl?: string;
  private key?: string;

  constructor(file?: any) {
    super();

    if (file) {
      this.file = file;

      const name = file.name;
      const lastDot = name.lastIndexOf('.');

      this.name = name.substring(0, lastDot);
      this.type = name.substring(lastDot + 1);
    }
  }

  static async fetchAll(predicate?: Record<string, unknown>) {
    try {
      const { data } = await Instance(true).request.get<{
        success: boolean;
        files: File[];
      }>('/v1/files', { params: predicate });

      return data.files.reverse();
    } catch (error) {
      throw new APIError(error);
    }
  }

  async getUploadURL(isVideo: boolean) {
    const fileParts = this.file.name.split('.');

    const { data } = await Instance(true).request.post<{
      uploadUrl: string;
      key: string;
      hash: string;
      name: string;
    }>(`/v1/files/get-upload-url`, {
      fileName: sanitize(this.name || fileParts[0]).toLowerCase(),
      fileType: this.type,
      isVideo: isVideo,
    });

    this.uploadUrl = data.uploadUrl;
    this.name = data.name;
    this.key = data.key;
    this.hash = data.hash;
  }

  async changeVisibility() {
    const { data } = await Instance(true).request.patch<{
      file: File;
    }>(`/v1/files/${this.hash}`);

    this.isPublic = !this.isPublic;
  }

  async upload(onProgress?: (progress: number) => void) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      await axios.put(this.uploadUrl, this.file, {
        onUploadProgress: (progress: ProgressEvent) => {
          return onProgress
            ? onProgress(progress.loaded / progress.total)
            : null;
        },
        ...options,
      });
    } catch (error) {
      throw error;
    }
  }

  async getUrl(): Promise<string> {
    const params = { key: this.key };

    const { data } = await Instance(true).request.get<{ url: string }>(
      `/v1/files/${this.hash}/url`,
      {
        params,
      },
    );

    this.downloadUrl = data.url;
    return this.downloadUrl;
  }

  async del(): Promise<void> {
    try {
      await Instance(true).request.delete(`/v1/files/${this.hash}`);
    } catch (error) {
      throw new APIError(error);
    }
  }
}

export default File;
