export default function sanitizeStr(str: string): string {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[\s_-]+/g, '-');
}

export const findDiff = (str1: string, str2: string) => {
  let diff = '';
  str2.split('').forEach(function (val, i) {
    if (val != str1.charAt(i)) diff += val;
  });
  return diff;
};
