import Logo from '../assets/img/logo_full.svg';
import './Layout.sass';
import React, { useEffect, useState } from 'react';
import { Quote, Star } from '../assets/img/icons';
import Testimonial from '../classes/Testimonials';
import { Crisp } from 'crisp-sdk-web';

interface LayoutProps {
  children: JSX.Element;
}

const TestimonialView = () => {
  const [testimonial, setTestimonial] = useState(null);

  useEffect(() => {
    Testimonial.getOneRandom().then((testimonial) =>
      setTestimonial(testimonial),
    );
  }, []);

  return testimonial ? (
    <div className={'bg-white rounded absolute bottom-12 mx-10 px-8 shadow-lg'}>
      <div className={'flex flex-col items-center'}>
        <span
          className={
            'bg-white w-min h-min px-4 py-6 rounded-full justify-self-center relative -top-10'
          }
        >
          <Quote
            className={'stroke-current text-teal-400'}
            style={{ width: '80px' }}
          />
        </span>
        <div className={'relative -top-6'}>
          <p>“{testimonial.testimonial}”</p>
          <div className={'flex justify-between items-center mt-8'}>
            <span className={'flex'}>
              <Star width={20} className={'fill-current text-teal-500'} />
              <Star width={20} className={'fill-current text-teal-500'} />
              <Star width={20} className={'fill-current text-teal-500'} />
              <Star width={20} className={'fill-current text-teal-500'} />
              <Star width={20} className={'fill-current text-teal-500'} />
            </span>
            <div className={'flex items-center justify-start'}>
              {testimonial.profilePictureUrl ? (
                <img
                  className={'w-10 h-10 rounded-full mr-3'}
                  src={testimonial.profilePictureUrl}
                />
              ) : null}
              <strong className={'text-teal-500'}>
                {testimonial.firstname} {testimonial.lastname.charAt(0)}.
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const Layout = ({ children }: LayoutProps) => {
  Crisp.chat.onChatOpened(() => {
    if (!Crisp.user.getEmail()) {
      Crisp.message.send('text', "Bonjour, j'ai une question pour vous.");
    }
  });

  return (
    <div className={'mx-auto w-screen h-screen flex bg-gray-100'}>
      <div
        className={
          'w-2/3 p-8 bg-gray max-h-screen flex flex-col items-center justify-start relative xs:w-full sm:w-full md:w-full lg:w-2/3'
        }
      >
        <div
          className={
            'w-full flex xs:items-center xs:justify-center md:justify-start pt-6'
          }
        >
          <img src={Logo} className={'top-2 left-2 w-1/4 xs:w-1/2'} />
        </div>
        <div
          className={'max-h-screen overflow-y-clip w-10/12 xs:w-full px-0.5'}
        >
          {children}
        </div>
      </div>
      <div className={'RightPanel relative w-3/6 sm:hidden xs:hidden lg:flex'}>
        {/* Testimonial */}
        <TestimonialView />
      </div>
    </div>
  );
};

export default Layout;
