import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './../assets/globals/style.css';
import '../assets/scss/SignupForm.sass';

import Input from '../components/forms/Input';
import Layout from '../components/Layout';
import User from '../classes/User';
import Alert, { AlertType } from '../components/Alert';
import GoogleSignInButton from '../components/forms/GoogleSignInButton';
import FacebookSignInButton from '../components/forms/FacebookSignInButton';
import { GoogleLoginResponse } from 'react-google-login';
import Button from '../components/forms/Button';
import { Enveloppe, Key } from '../assets/img/icons';

import { useAppSelector } from '../hooks/redux';
import { useDispatch } from 'react-redux';
import { login } from '../actions/user.action';
import { clearErrors } from '../reducers/user.reducer';
import trackSegment, { identifySegment } from '../hooks/useSegment';
import useSearchParams from '../hooks/useSearchParams';
import { UserRole } from '../classes/enums/UserRole';
import AppleLoginButton, {
  AppleResponse,
} from '../components/forms/Buttons/AppleLogin';
import clsx from 'clsx';

enum Field {
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}

const SignIn = () => {
  const [user, setUser] = useState(new User());
  const navigate = useHistory();

  const loading = useAppSelector((state) => state.user.isLoading);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.user.error);

  const [popup, setPopup] = useState<JSX.Element | null>(null);
  const dispatch = useDispatch();

  const search = useSearchParams<{ error?: string; redirect?: string }>();

  useEffect(() => {
    let url = `/`;
    if (currentUser) {
      identifySegment(currentUser);
      if (search.redirect) {
        url = search.redirect;
      } else {
        switch (currentUser.role) {
          case UserRole.freeUser:
          case UserRole.clubMember:
          case UserRole.paying:
            url = `/`;
            break;
          case UserRole.teamMember:
          case UserRole.admin:
          case UserRole.superAdmin:
            url = `/admin/customers`;
            break;
          case UserRole.banned:
            url = `/se-connecter?error=banned`;
            break;
        }
      }
      navigate.push(url);
    } else if (error) {
      setPopup(
        <Alert
          type={AlertType.alert}
          body={<p>{error}</p>}
          title={'Une erreur est survenue'}
          onClose={() => dispatch(clearErrors())}
          width={'w-full'}
        />,
      );
    } else {
      setPopup(null);
    }
  }, [currentUser, error]);

  const signIn = async () => {
    dispatch(login(user));
  };

  const facebookSignIn = async (facebookResponse) => {
    const _user = user;
    _user.email = facebookResponse.email;
    _user.facebookId = facebookResponse.id;
    setUser(_user);
    await signIn();
  };

  const appleSignIn = async (appleResponse: AppleResponse) => {
    const _user = user;

    if (appleResponse.error) {
      return;
    }

    if (appleResponse.user) {
      _user.firstname = appleResponse.user.name.firstName;
      _user.lastname = appleResponse.user.name.lastName;
      _user.email = appleResponse.user.email;
    }
    if (appleResponse.authorization) {
      _user.apple_id = appleResponse.authorization.id_token;
    }

    setUser(_user);
    await signIn();
  };

  const googleSignIn = async (googleObject) => {
    const _user = user;
    _user.email = googleObject.email;
    _user.googleId = googleObject.id;
    _user.firstname = googleObject.givenName;
    _user.lastname = googleObject.familyName;

    setUser(_user);
    await signIn();
  };

  const updateFields = (value: string, field: Field) => {
    const _user = user;

    switch (field) {
      case Field.email:
        _user.email = value;
        break;
      case Field.password:
        _user.password = value;
        break;
    }
    setUser(_user);
  };

  return (
    <Layout>
      <>
        {popup}
        <h2 className={'text-2xl font-oswald font-medium mb-6 text-gray-600'}>
          Connectez-vous à votre compte
        </h2>

        {/*<div
          className={
            'flex flex-col md:flex-row justify-between grid-rows-2 mb-2 gap-1'
          }
        >
          <FacebookSignInButton
            showText={false}
            rounded={false}
            buttonText={'Se connecter avec Facebook'}
            facebookResponseHandler={async (res) => await facebookSignIn(res)}
          />
          <AppleLoginButton
            text={'Se connecter avec Apple'}
            callback={appleSignIn}
            showText={false}
          />
          <GoogleSignInButton
            showText={false}
            rounded={false}
            buttonText={'Se connecter avec Google'}
            handleSuccess={(success: GoogleLoginResponse) =>
              googleSignIn(success.profileObj)
            }
            handleFailure={(err) => console.log(err)}
          />
        </div>
        <hr />*/}

        <Input
          leftComponent={
            <Enveloppe className={'stroke-current text-gray-500'} />
          }
          title={'Email'}
          type={'email'}
          autocomplete={'email'}
          placeholder={'Votre email'}
          required
          onChange={(v) => updateFields(v, Field.email)}
        />
        <Input
          leftComponent={<Key className={'stroke-current text-gray-500'} />}
          title={'Mot de passe'}
          type={'password'}
          placeholder={'Votre mot de passe'}
          autocomplete={'current-password'}
          required
          onChange={(v) => updateFields(v, Field.password)}
        />

        <button
          onClick={signIn}
          disabled={loading}
          className={clsx(
            'mt-4 flex-1 bg-teal-600 font-oswald text-white w-full rounded text-xl py-3 hover:shadow hover:bg-teal-800',
          )}
        >
          Connexion
        </button>

        <div className={'text-sm text-teal mt-8 ml-2'}>
          <p className={'mb-4'}>
            Je n'ai pas de compte :{' '}
            <Link
              to={'/s-inscrire'}
              className={
                'font-bold text-teal-600 hover:underline hover:underline-offset-2'
              }
            >
              Je m'inscris
            </Link>
          </p>
          <p>
            Mot de passe oublié :{' '}
            <Link
              to={'/oubli-mot-de-passe'}
              className={
                'font-bold text-teal-600 hover:underline hover:underline-offset-2'
              }
            >
              Je change mon mot de passe
            </Link>
          </p>
        </div>
      </>
    </Layout>
  );
};

export default SignIn;
