import { AppThunk } from '../../store';
import User from '../../classes/User';
import Subscription from './subscription.model';
import { getSubscriptionsSuccess, setLoading } from './subscriptions.reducer';

export const listSubscriptions =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const subscriptions = await Subscription.getAll(user);
      dispatch(getSubscriptionsSuccess(subscriptions));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
