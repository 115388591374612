import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Program from '../classes/Program';
import Training from '../classes/Training';

type ProgramState = {
  isLoading: boolean;
  programs: Program[];
  current: Program;
  error: string;
};
const initialState: ProgramState = {
  isLoading: false,
  current: null,
  programs: [],
  error: null,
};

const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = !state.isLoading;
    },
    fetchProgramSuccess(state, action: PayloadAction<Program[]>) {
      state.programs = action.payload;
    },
    fetchOneProgramSuccess(state, action: PayloadAction<Program>) {
      state.current = action.payload;
    },
    createProgramSuccess(state, action: PayloadAction<Program>) {
      state.programs.push(action.payload);
    },
    updateProgramSuccess(state, action: PayloadAction<Program>) {
      const index = state.programs.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.programs.splice(index, 1, action.payload);
    },
    deleteProgramSuccess(state, action: PayloadAction<Program>) {
      const index = state.programs.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.programs.splice(index, 1);
    },
    programError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearProgram(state, action: PayloadAction<Program>) {
      state.current = null;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export default programSlice.reducer;
export const {
  startLoading,
  fetchProgramSuccess,
  fetchOneProgramSuccess,
  createProgramSuccess,
  updateProgramSuccess,
  deleteProgramSuccess,
  programError,
  clearError,
  clearProgram,
} = programSlice.actions;
