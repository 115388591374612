import React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../../assets/img/icons';
import clsx from 'clsx';

interface IButton {
  onClick?: (string) => void;
  children: JSX.Element | string;
  backgroundColor?: string;
  rounded?: boolean;
  disabled?: boolean;
  otherClasses?: string;
  tip?: string | JSX.Element;
  loading?: boolean;
  loadingText?: string;
  hasError?: boolean;
  error?: string;
}

interface IButtonLink {
  href: string;
}

const Button = ({
  backgroundColor = 'bg-teal-500 hover:bg-teal-700',
  rounded = true,
  disabled = false,
  loading = false,
  loadingText = 'Chargement',
  onClick,
  otherClasses = 'text-white',
  children,
  tip,
}: IButton) => {
  const id = 'button+' + Math.random().toFixed(4).toString();
  return (
    <button
      data-tip={tip}
      className={clsx(
        `h-auto px-4 py-3 flex items-center item-stretch cursor-pointer hover:shadow-lg hover:outline-none focus:outline-none rounded-xl disabled:bg-slate-200 disabled:text-slate-500 disabled:cursor-not-allowed disabled:shadow-none`,
        rounded && 'rounded-full',
        backgroundColor,
        otherClasses,
      )}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <div className={'flex w-full items-center justify-center'}>
          <Loading className={'w-8 h-8 animate-spin stroke-current mr-2'} />{' '}
          {loadingText}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

const ButtonExternalLink: React.FC<IButton & IButtonLink> = ({
  backgroundColor = 'bg-teal-500 hover:bg-teal-700',
  rounded = true,
  otherClasses = 'text-white',
  children,
  tip,
  href,
}: IButton & IButtonLink) => {
  const id = 'button+' + Math.random().toFixed(4).toString();
  return (
    <a
      data-tip={tip}
      className={`h-auto px-4 py-3 flex items-center item-stretch justify-between cursor-pointer shadow-md hover:shadow-lg hover:outline-none focus:outline-none
        ${rounded ? 'rounded-full' : 'rounded-lg'}  ${backgroundColor}
        ${otherClasses}
        disabled:bg-gray-200 disabled:opacity-50 disabled:text-black disabled:cursor-not-allowed disabled:ring-2 disabled:ring-gray-600
        `}
      id={id}
      href={href}
      target={'_blank'}
      rel={'noreferrer'}
    >
      {children}
    </a>
  );
};

const ButtonInternalLink: React.FC<IButton & IButtonLink> = ({
  backgroundColor = 'bg-teal-500 hover:bg-teal-700',
  rounded = true,
  otherClasses = 'text-white',
  children,
  tip,
  href,
}: IButton & IButtonLink) => {
  const id = 'button+' + Math.random().toFixed(4).toString();
  return (
    <Link to={href}>
      <span
        data-tip={tip}
        className={`h-auto px-4 py-3 flex items-center item-stretch justify-between cursor-pointer shadow-md hover:shadow-lg hover:outline-none focus:outline-none
        ${rounded ? 'rounded-full' : 'rounded-lg'}  ${backgroundColor}
        ${otherClasses}
        disabled:bg-gray-200 disabled:opacity-50 disabled:text-black disabled:cursor-not-allowed disabled:ring-2 disabled:ring-gray-600
        `}
        id={id}
      >
        {children}
      </span>
    </Link>
  );
};

export default Button;
export { ButtonExternalLink, ButtonInternalLink };
