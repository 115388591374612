import { AppThunk } from '../store';
import User from '../classes/User';
import Subscription from '../features/subscriptions/subscription.model';
import {
  getProgressionSuccess,
  setLoading,
} from '../reducers/progression.reducer';
import Advancement from '../classes/Advancement';
import { startLoading, userActionFailure } from '../reducers/user.reducer';

export const loadProgressionAction =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const progression = await Advancement.get(user, false, false);
      dispatch(getProgressionSuccess(progression));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const loadActiveProgression =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const progression = await Advancement.get(user, true, false);
      dispatch(getProgressionSuccess(progression));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const goToNextDay =
  (user: User, advancement: Advancement): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await advancement.nextDay(user);
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };
