import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './../assets/globals/style.css';
import '../assets/scss/SignupForm.sass';

import Input from '../components/forms/Input';
import Layout from '../components/Layout';
import User from '../classes/User';
import { useHistory } from 'react-router-dom';

import { identifySegment } from '../hooks/useSegment';
import SegmentEvents from '../constants/SegmentEvents';
import useSearchParams from '../hooks/useSearchParams';
import useUser from '../hooks/useUser';
import { UserRole } from '../classes/enums/UserRole';
import { Event } from '../constants/SegmentEvents';

const AdminConnect = () => {
  const { user, fetchUser } = useUser();
  const navigate = useHistory();

  const search = useSearchParams<{ token?: string }>();
  useEffect(() => {
    if (user) {
      navigate.replace('/');
      identifySegment(user);
      Event.LOGIN_AS_ADMIN.track();
    }
  }, [user]);

  useEffect(() => {
    if (search.token) {
      const u = new User();
      u.storeJwt(search.token);
      fetchUser();
    }
  }, [search.token]);

  return (
    <Layout>
      <>
        <h2 className={'text-2xl font-medium mb-6 text-gray-600'}>
          Connexion utilisateur, merci de patienter
        </h2>
        <h3 className={'text-xl font-medium mb-6 text-black'}>
          Pensez a refermer cette fenetre une fois l'opération terminée
        </h3>

        <hr />
      </>
    </Layout>
  );
};

export default AdminConnect;
