import { IThreeColumnsLayout } from '../../interfaces/contentfulModels';
import clsx from 'clsx';
import React, { FC } from 'react';
import contentParagraph from '../Content/ContentParagraph';
import OptinParagraph from './OptinParagraph';

const ColumnLayout: FC<{ data: IThreeColumnsLayout }> = ({ data }) => {
  const bgName = `--background-${data.sys.id}`;
  const twBgName = `bg-[var(${bgName})]`;

  if (data.fields.background) {
    document.documentElement.style.setProperty(bgName, data.fields.background);
    //text-[var(--my-var)]
  }

  let cols: string;

  switch (data.fields.columns_number) {
    case 1:
      cols = 'grid-cols-1';
      break;
    case 2:
      cols = 'xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2';
      break;
    case 3:
      cols = 'xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3';
      break;
    case 4:
      cols = 'xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-4';
      break;
  }

  return (
    <div
      className={clsx('grid', cols)}
      style={{ background: data.fields.background }}
    >
      {data.fields.content.map((contentParagraph) => {
        return (
          <OptinParagraph
            shouldReverse={false}
            paragraph={contentParagraph}
            key={contentParagraph.sys.id}
          />
        );
      })}
    </div>
  );
};

export default ColumnLayout;
