import React, { useEffect, useState } from 'react';
import './../assets/globals/style.css';
import '../assets/scss/SignupForm.sass';

import Layout from '../components/Layout';
import User from '../classes/User';

import { useAppSelector } from '../hooks/redux';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../actions/user.action';
import { identifySegment } from '../hooks/useSegment';
import Cookies from 'js-cookie';
import Loader from '../components/Loader/Loader';
import { UserRole } from '../classes/enums/UserRole';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';

const ConnexionPurchase = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.user.error);

  const dispatch = useDispatch();
  const navigate = useHistory();

  useEffect(() => {
    const cookie = Cookies.get('_rj_auth_token');
    if (cookie) {
      const user = new User();
      user.storeJwt(cookie);
      dispatch(getCurrentUser(user));
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate.replace('/');
      identifySegment(currentUser);
    }
  }, [currentUser, error]);

  return (
    <Layout>
      <div className={'flex flex-col items-center justify-center w-min-screen'}>
        <h2 className={'text-2xl font-medium mb-6 text-gray-600 mn-12'}>
          Patientez quelques secondes...
        </h2>
        <Loader color={'turquoise'} />
      </div>
    </Layout>
  );
};

export default ConnexionPurchase;
