import React, { useEffect, useState } from 'react';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { cgu, cgv, mentions, privacy } from './content';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

const ConditionsGeneralesPage = () => {
  const navigate = useHistory();
  const params = useParams<{ route: 'cgv' | 'cgu' | 'privacy' | 'mentions' }>();
  const [content, setContent] = useState('');

  useEffect(() => {
    switch (params.route) {
      case 'cgu':
      case 'cgv':
        setContent(cgv);
        break;
      case 'privacy':
        setContent(privacy);
        break;
      case 'mentions':
        setContent(mentions);
        break;
    }
  }, [params.route]);

  return (
    <div className={'bg-teal-600 flex w-screen h-screen justify-center'}>
      <span
        className={'absolute top-6 left-6 flex text-white'}
        onClick={() => navigate.go(-1)}
      >
        <ArrowLeftIcon className={'w-4 mr-2'} />
        Revenir a la page précédente{' '}
      </span>
      <div
        className={'w-3/4 p-6 my-6 mt-20 bg-white rounded-lg overflow-y-scroll'}
      >
        <ReactMarkdown
          remarkPlugins={[gfm]}
          components={{
            p: ({ node, ...props }) => (
              <p style={{ marginTop: 12, marginBottom: 12 }} {...props} />
            ),
            h1: ({ node, ...props }) => (
              <h1
                className={'font-oswald uppercase font-black text-2xl'}
                style={{ marginTop: 12, marginBottom: 12 }}
                {...props}
              />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default ConditionsGeneralesPage;
