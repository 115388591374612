import {
  INutritionReceipeTime,
  INutritionReceipeTimeFields,
} from '../../interfaces/contentfulModels';
import React from 'react';

const ReceipeTime = ({
  receipeTime,
}: {
  receipeTime: INutritionReceipeTime;
}) => {
  return (
    <div
      className={
        'bg-white rounded shadow w-full p-6 flex flex-col items-center'
      }
    >
      <span>
        <strong>Temps de préparation : </strong> {receipeTime.fields.total} min.
      </span>
      <hr className={'border-content-secondary border-2 rounded my-2 w-full'} />
      <div className={'flex justify-evenly w-full'}>
        <span>
          <strong>Temps de préparation :</strong>{' '}
          {receipeTime.fields.preparation} min
        </span>
        {!!receipeTime.fields.rest && (
          <span>
            <strong>Temps de repos :</strong> {receipeTime.fields.rest}
          </span>
        )}
        {!!receipeTime.fields.baking && (
          <span>
            <strong>Temps de cuisson :</strong> {receipeTime.fields.baking}
          </span>
        )}
      </div>
    </div>
  );
};

export default ReceipeTime;
