import { Instance, TOKEN_KEY } from './API/API';
import APIError from './APIError';
import Stripe from 'stripe';
import Base from './Base';
import { ProgramLevel } from './Program';
import Advancement from './Advancement';

class Testimonial extends Base {
  firstname?: string;
  lastname?: string;
  testimonial?: string;

  profilePictureUrl: string;

  static async getAll() {
    try {
      const { data } = await Instance(true).request.get<{
        testimonials: Testimonial[];
      }>(`/v1/testimonials`);

      return data.testimonials.map((t) => Object.assign(new Testimonial(), t));
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  static async getOneRandom() {
    const { data } = await Instance(false).request.get<{
      success: boolean;
      testimonial: Testimonial;
    }>(`/v1/testimonials/one`);

    return Object.assign(new Testimonial(), data.testimonial);
  }
}

export default Testimonial;
