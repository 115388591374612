import * as React from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import Signin from './pages/signin';
import { Provider } from 'react-redux';

import configureStore from './store';
import initialState from './store/initialState';

import Signup from './pages/signup';
import ResetPassword from './pages/resetPassword';

import './assets/globals/style.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ScrollToTop from './components/ScrollToTop';
import SegmentAnalytics from './components/SegmentAnalytics';
import { useEffect } from 'react';
import EventFactory from './constants/SegmentEvents';
import CreateProgram from './pages/createProgramme';
import ConnexionPurchase from './pages/conexionPurchase';
import ConditionsGeneralesPage from './pages/legal/legal';
import AdminConnect from './pages/connect';
import ChallengeConnect from './pages/connectChallenge';
import MemberCard from './pages/app/MemberCard';
import OptinPage from './pages/app/optin/optin';
import ReactPixel from 'react-facebook-pixel';
import { addMonths, setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Toaster } from 'react-hot-toast';
import { Crisp } from 'crisp-sdk-web';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.SENTRY_TRACE_RATE
    ? parseFloat(process.env.SENTRY_TRACE_RATE)
    : 1,
});

const store = configureStore(initialState);
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY, {
  locale: 'fr',
});

document.addEventListener('copy', async (e) => {
  if (!window.location.href.includes('/admin')) {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(
      "La copie n'est pas autorisée, poil au nez 🏴‍☠️",
    );
  }
});

setDefaultOptions({ locale: fr });

const App = () => {
  useEffect(() => {
    EventFactory.initSegmentClient();

    /* Facebook Pixel */
    ReactPixel.init('4482495275154597', undefined, {
      autoConfig: true,
      debug: false,
    });
  }, []);

  const AppRouter = React.lazy(() => import('./App/AppRouter'));

  useEffect(() => {
    console.log('RJF : Init. Crisp');
    Crisp.configure('d26e5483-2432-4bcb-b094-d7b946ddc9bb');
    Crisp.load();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {/* shouldDisplayBanner ? (
        <WarningBanner />
      ) : null*/}
      <>
        <Toaster />
        <Provider store={store}>
          <BrowserRouter>
            <SegmentAnalytics />
            <ScrollToTop />
            <Switch>
              <Route path={'/s-inscrire'}>
                <Signup />
              </Route>
              <Route path={'/inscription/:slug'}>
                <OptinPage />
              </Route>
              <Route path={'/merci'}>
                <ConnexionPurchase />
              </Route>
              <Route path={'/se-connecter'}>
                <Signin />
              </Route>
              <Route path={'/connexion-challenge'}>
                <ChallengeConnect />
              </Route>
              <Route path={'/connexion-admin'}>
                <AdminConnect />
              </Route>
              <Route path={'/oubli-mot-de-passe'}>
                <ResetPassword />
              </Route>
              <Route path="/mon-programme">
                <CreateProgram />
              </Route>
              <Route path="/legal/:route">
                <ConditionsGeneralesPage />
              </Route>
              <Route path="/ma-carte-de-membre">
                <MemberCard />
              </Route>
              <React.Suspense fallback={<div>Chargement...</div>}>
                <AppRouter />
              </React.Suspense>
            </Switch>
          </BrowserRouter>
        </Provider>
      </>
    </Elements>
  );
};

let exportable;
if (process.env.NODE_ENV !== 'production') {
  exportable = hot(module)(App);
} else {
  exportable = App;
}

export default exportable;
