import Price from './Price';
import { Instance } from './API/API';

export enum ProductType {
  Club = 'Club',
  PlanNutri = 'Plan_Nutri',
  Ebook = 'Ebook',
  Unknown = 'Unknown',
}

export default class Product {
  id?: number;
  hash?: string;
  stripeId?: string;
  active = true;
  stripeName?: string;
  description = '';
  prices?: Price[];
  type: ProductType = ProductType.Unknown;
  sellable: Record<string, any>;

  createdAt: Date = new Date();
  updatedAt?: Date = new Date();

  async getOne(): Promise<Product> {
    try {
      const { data } = await Instance(false).request.get<{
        message: string;
        data: {
          product: Product;
        };
      }>(`/v1/products/${this.hash}`);

      return Object.assign(new Product(), data.data.product);
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }
  static async getAll(): Promise<Product[]> {
    try {
      const { data } = await Instance(false).request.get<{
        message: string;
        data: { products: Product[] };
      }>(`/v1/products`);
      return data.data.products.map((p: Product) => {
        return Object.assign(new Product(), p);
      });
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }
}
