import { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import { TOKEN_KEY } from '../classes/API/API';
import { fromUnixTime, isBefore } from 'date-fns';
import { UserRole } from '../classes/enums/UserRole';
import { useAppSelector } from './redux';

const useAuth = () => {
  const user = useAppSelector((store) => store.user.currentUser);
  const [isAuth, setIsAuth] = useState<boolean>(null);
  const [isFreeUser, setIsFreeUser] = useState<boolean>(null);
  const [isClubMember, setClubMember] = useState<boolean>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(null);
  const [isTeam, setIsTeam] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string | null>(null);

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN_KEY);

    const adminRoles = [UserRole.admin, UserRole.superAdmin];

    const teamRoles = [
      UserRole.teamMember,
      UserRole.admin,
      UserRole.superAdmin,
    ];
    const clubAccessRoles = [
      UserRole.paying,
      UserRole.clubMember,
      UserRole.fullAccess,
      UserRole.admin,
      UserRole.superAdmin,
    ];
    const challengeAccessRoles = [
      UserRole.freeUser,
      UserRole.fullAccess,
      UserRole.admin,
      UserRole.superAdmin,
    ];

    if (token) {
      const tokenPayload = jwt.decode(token);
      setUserId(tokenPayload.userId);
      const tokenExpiry = fromUnixTime(tokenPayload.exp);
      setIsAuth(
        token &&
          isBefore(new Date(), tokenExpiry) &&
          tokenPayload.role !== UserRole.banned,
      );

      setIsAdmin(token && adminRoles.includes(tokenPayload.role));
      setIsTeam(token && teamRoles.includes(tokenPayload.role));
      setClubMember(token && clubAccessRoles.includes(tokenPayload.role));
      setIsFreeUser(token && challengeAccessRoles.includes(tokenPayload.role));
      setFirstname(tokenPayload.firstname);
    }

    setLoading(false);
  }, [user]);
  return {
    loading,
    isAuth,
    isAdmin,
    isTeam,
    isFreeUser,
    isClubMember,
    userId,
    firstname,
  };
};

export default useAuth;
