import React, { useEffect, useState } from 'react';
import { Logo, LogoColored } from '../../assets/img/icons';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';

import { fr } from 'date-fns/locale';
import useUser from '../../hooks/useUser';
import useSubscriptions from '../../hooks/useSubscriptions';
import Subscription from '../../features/subscriptions/subscription.model';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import html2canvas from 'html2canvas';
import { useHistory } from 'react-router-dom';

export default function (): JSX.Element {
  const { user, fetchUser } = useUser();
  const { subscriptions, loadSubscriptions } = useSubscriptions();
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const navigate = useHistory();

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
    loadSubscriptions(true);
  }, [user]);

  useEffect(() => {
    setSubscription(
      subscriptions.find(
        (s) => s.product.type === 'Club' && s.status === 'active',
      ),
    );
  }, [subscriptions]);

  const saveAsPdf = async () => {
    const node = document.getElementById('memberCard');

    const canvas = await html2canvas(node, {
      logging: true,
      profile: true,
      useCORS: true,
    });

    const data = canvas.toDataURL('image/png');
    const src = encodeURI(data);

    const a = document.createElement('a');
    a.href = src; //Image Base64 Goes here
    a.download = `carte-rester-jeune-${user?.firstname}-${
      user?.lastname
    }-${format(new Date(), 'dd-MM-yyyy-HH-mm')}`.toLowerCase(); //File name Here
    a.click(); //Downloaded file
  };

  return (
    <div
      className={
        'flex flex-col gap-12 items-center justify-center h-screen w-screen dark:bg-black bg-slate-100'
      }
    >
      <button
        onClick={() => navigate.go(-1)}
        className={
          'print:hidden text-black dark:text-white flex w-fit items-center absolute top-2 left-2'
        }
      >
        <ChevronLeftIcon className={'h-6 w-6'} /> Retourner au site
      </button>
      <div
        className={
          'bg-white ring-[12px] ring-gray-200 print:ring-[12px] print:ring-gray-200 h-[20em] w-[32em] rounded-lg shadow shadow-white p-6 relative overflow-clip hover:animate-threed'
        }
        id={'memberCard'}
      >
        <img
          src={
            require('../../assets/img/julien_rj_bleu_montre_epaule.png').default
          }
          className={'absolute -bottom-2 left-3 h-80'}
        />

        <div
          className={
            'flex flex-col font-oswald text-xl absolute px-4 py-0.5 top-4 right-0 rounded-l text-right w-2/3'
          }
        >
          <img
            src={require('../../assets/img/logo_full.png').default}
            className={'w-40 self-end'}
          />
          <div className={'relative'}>
            <div className={'flex flex-col relative'}>
              <span
                className={
                  'absolute left-0 absolute text-2xl text-blue-900 left-4'
                }
              >
                membre
              </span>
              <span
                className={
                  'text-5xl uppercase text-teal-600 font-bold absolute left-8 -bottom-20'
                }
              >
                Club
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            'font-plexmono uppercase text-lg text-slate-800 font-bold absolute px-4 py-0.5 bottom-16 right-0 rounded-l text-right'
          }
        >
          {!!user?.gender && user.gender !== 'unspecified'
            ? user.gender === 'female'
              ? 'Mme'
              : 'M.'
            : ''}
          {user?.firstname} {user?.lastname} <br />
          <span className={'text-base'}>
            Adhésion n°{user?.id.toString().padStart(5, '0')}
          </span>
        </div>

        {subscription && (
          <div
            className={
              'bg-blue-900 uppercase text-white w-full shadow absolute px-4 py-0.5 bottom-4 text-sm'
            }
          >
            Adhésion valable du{' '}
            {format(parseISO(subscription.currentPeriodStart), 'd MMMM', {
              locale: fr,
            })}{' '}
            au{' '}
            {format(parseISO(subscription.currentPeriodEnd), 'd MMMM yyyy', {
              locale: fr,
            })}
          </div>
        )}
      </div>

      <div className={'flex gap-2'}>
        <button
          onClick={saveAsPdf}
          className={
            'print:hidden text-white bg-teal-600 p-4 rounded-full hover:bg-teal-800'
          }
        >
          Télécharger (PNG)
        </button>
        <button
          onClick={window.print}
          className={
            'print:hidden text-white bg-teal-600 p-4 rounded-full hover:bg-teal-800'
          }
        >
          Imprimer ma carte
        </button>
      </div>
    </div>
  );
}
