// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/img/julien/rj_blue_pointing_left.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RightPanel{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.RightPanel .Overlay{background-color:rgba(0,60,141,.3)}", "",{"version":3,"sources":["webpack://./components/Layout.sass"],"names":[],"mappings":"AAAA,YAEE,kDAAA,CACA,qBAAA,CAEA,qBACE,kCAAA","sourcesContent":[".RightPanel\n\n  background: url('../assets/img/julien/rj_blue_pointing_left.png')\n  background-size: cover\n\n  .Overlay\n    background-color: rgba(0, 60, 141, 0.3)\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
