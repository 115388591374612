import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from '../classes/User';
import File from '../classes/File';
import Training from '../classes/Training';

type UserState = {
  isLoading: boolean;
  files: File[];
  error?: string;
  message?: string;
};

const initialState: UserState = {
  isLoading: false,
  files: [],
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = !state.isLoading;
    },
    getAllSuccess(state, action: PayloadAction<File[]>) {
      state.files = action.payload;
    },
    createSuccess(state, action: PayloadAction<File>) {
      state.files.push(action.payload);
    },
    editFileSuccess(state, action: PayloadAction<File>) {
      const index = state.files.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.files.splice(index, 1, action.payload);
    },
    deleteSuccess(state, action: PayloadAction<File>) {
      const index = state.files.findIndex(
        (elt) => elt.hash === action.payload.hash,
      );
      state.files.splice(index, 1);
    },
    fileError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearErrors(state) {
      state.error = null;
    },
  },
});

export const {
  startLoading,
  getAllSuccess,
  createSuccess,
  editFileSuccess,
  deleteSuccess,
  fileError,
  clearErrors,
} = filesSlice.actions;

export default filesSlice.reducer;
